import React, {memo} from 'react';

import PropTypes from 'prop-types';

import {blue, lightGray} from '@renofi/utils/src/colors';
import {Badge} from '@renofi/components';
import {toCurrency} from '@renofi/utils/src/format';

import {Tooltip} from '../../Tooltips';

import {Wrapper, Title, Value, TooltipWrapper} from './styled';

const SectionSummary = ({
  value,
  title = '',
  disabled,
  isSticky,
  children,
  badge,
  isPublic,
  loanProduct,
  lender,
  ...props
}) => {
  const id = title.replace(/ /g, '-').toLowerCase();
  const {interestOnly, productType} = loanProduct || {};

  return (
    <Wrapper id={id} isSticky={isSticky} {...props}>
      <TooltipWrapper isSticky={isSticky}>
        {disabled || (
          <Tooltip
            isPublic={isPublic}
            id={title}
            lender={lender}
            interestOnly={interestOnly}
            productType={productType}
          />
        )}
      </TooltipWrapper>
      <Title disabled={disabled}>{title}</Title>
      <Value
        data-testid={`${id}-value`}
        isSticky={isSticky}
        color={Math.round(value) <= 0 ? lightGray : 'black'}>
        {toCurrency(value)}
        {badge ? (
          <Badge ml={10} mt={['-2px', '2px']} color={blue}>
            {badge}
          </Badge>
        ) : null}
      </Value>
      {children}
    </Wrapper>
  );
};

SectionSummary.propTypes = {
  value: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  color: PropTypes.string.isRequired,
  badge: PropTypes.string,
  disabled: PropTypes.bool,
  isSticky: PropTypes.bool,
  isPublic: PropTypes.bool,
  loanProduct: PropTypes.object,
  lender: PropTypes.object,
};

export default memo(SectionSummary);
