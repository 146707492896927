import {useEffect} from 'react';

export default function useKeyDown(key, callback, dependencies) {
  async function onKeyDown(e) {
    if (e.key === key) {
      callback && callback();
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, dependencies);
}
