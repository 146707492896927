import React from 'react';

import {animated, useSpring} from 'react-spring';

import {jumpIn} from '@renofi/utils/src/animation';

import {ReactComponent as Lamp} from './img/lamp.svg';
import {ReactComponent as Table} from './img/table.svg';

const AnimatedTable = animated(Table);

const DiningRoom = () => {
  return (
    <div
      id="dining-room"
      style={{
        width: 76,
        height: 60,
        position: 'absolute',
        overflow: 'hidden',
        zIndex: 101,
        left: 0,
        top: 140,
      }}>
      <Lamp
        style={{
          position: 'absolute',
          left: 33,
        }}
      />
      <AnimatedTable
        style={{
          position: 'absolute',
          top: 37,
          left: 15,
          ...useSpring({
            from: {marginTop: 210},
            to: {marginTop: 0},
            delay: 1400,
            config: jumpIn,
          }),
        }}
      />
    </div>
  );
};

export default DiningRoom;
