import React from 'react';

import {PanelDark, Toggle} from '@renofi/components';
import {productTypes} from '@renofi/api';

import {Text} from '../styled';

const Rate = ({productType, isPublic}) => {
  const renofiHeloc = productType === productTypes.RENOFI_HELOC;

  return (
    <PanelDark title="Interest rate">
      <Toggle show={!isPublic}>
        <Text mb={10}>
          Interest rate{renofiHeloc ? '' : ', loan term'} and loan amount are
          used to calculate the estimated minimum monthly payment.
        </Text>

        <Text mb={10}>
          The rate shown is not an APR. Fees vary by bank and will determine the
          final APR.
        </Text>
      </Toggle>

      <Toggle show={isPublic}>
        <Text mb={10}>
          Interest rate is defaulted to using the lowest rate offered by select
          lenders based on current market conditions and applicants with
          excellent credit as determined by each lender's credit criteria
          (typically, a credit score of at least 740 and debt-to-income of 50%
          or less). Rates subject to change without notice. Actual rate
          determined by lender.
        </Text>
      </Toggle>
    </PanelDark>
  );
};

export default Rate;
