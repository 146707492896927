import React from 'react';

import PropTypes from 'prop-types';

import {PanelDark} from '@renofi/components';

import {Text} from '../styled';

const MortgageBalanceAdu = () => {
  return (
    <PanelDark title="Total outstanding mortgage balance on the property">
      <Text mb={10}>
        The total outstanding mortgage balance on the property where the ADU
        project is taking place contributes to the maximum loan amount that you
        can borrow.
      </Text>
    </PanelDark>
  );
};

MortgageBalanceAdu.propTypes = {
  project: PropTypes.object,
};

export default MortgageBalanceAdu;
