import React from 'react';

import PropTypes from 'prop-types';

import {PanelDark} from '@renofi/components';

import {Text} from '../styled';

const LoanAmount = ({maxLoan}) => {
  return (
    <PanelDark title="Loan amount">
      <Text mb={10}>
        The amount you are looking to borrow for your home renovation.
      </Text>
      <Text>
        You can borrow up to your{' '}
        <strong>
          max loan amount
          {maxLoan ? ` of $${maxLoan.toLocaleString('en-US')}` : ''}
        </strong>
        .
      </Text>
    </PanelDark>
  );
};

LoanAmount.propTypes = {
  maxLoan: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default LoanAmount;
