import {useQuery} from '@apollo/client';

import {IS_EMAIL_TAKEN} from '../queries/isEmailTaken';

export default function useIsEmailTaken() {
  const {refetch} = useQuery(IS_EMAIL_TAKEN, {skip: true});
  const imperativelyCallQuery = (variables) => {
    return refetch(variables);
  };
  return imperativelyCallQuery;
}
