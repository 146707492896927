import styled from '@emotion/styled';
import {keyframes} from '@emotion/react';

const shakeAnimation = keyframes`
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
`;

export const Wrapper = styled.div({}, ({shake}) => {
  return {
    animation: shake ? `${shakeAnimation} .8s ease-out` : 'none',
  };
});
