import React from 'react';

import PropTypes from 'prop-types';
import {useReactiveVar} from '@apollo/client';

import {PanelDark, Toggle} from '@renofi/components';

import {Text, Strong, Link} from '../styled';
import {calculatedValuesVar} from '../../api/cache/maxLoan';

const MaxLoan = ({isPublic}) => {
  const {isMaxLoanFromDti} = useReactiveVar(calculatedValuesVar);

  return (
    <PanelDark title="Your max loan amount">
      <Text mb={10}>
        The maximum amount that you can borrow is based on the following
        information provided by you:
      </Text>
      <Text>• Current home value</Text>
      <Text>• Total outstanding mortgage balance</Text>
      <Text>• Expected home value post renovation</Text>
      <Toggle show={isMaxLoanFromDti}>
        <Text>• Debt-to-income ratio</Text>
      </Toggle>
      <Text mt={10}>And the loan program limits.</Text>
      <Toggle show={!isPublic}>
        <Strong mt={10}>Related reading from our blog</Strong>
        <Link
          target="_blank"
          href="https://www.renofi.com/blog/how-appraisals-work-for-renovation-loans/">
          How do appraisals work for renovation loans?
        </Link>
      </Toggle>
    </PanelDark>
  );
};

MaxLoan.propTypes = {
  project: PropTypes.object,
};

export default MaxLoan;
