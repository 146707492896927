import {useEffect} from 'react';

const getDimensions = (ref, key) => {
  const current = key ? ref?.current[key] : ref.current;
  return {
    width: current?.getBoundingClientRect()?.width,
    height: current?.getBoundingClientRect()?.height,
  };
};

export default (ref, key) => {
  let dimensions = {width: 0, height: 0};
  if (ref.current) {
    dimensions = getDimensions(ref, key);
  }

  useEffect(() => {
    const handleResize = () => {
      if (ref.current) {
        dimensions = getDimensions(ref, key);
      }
    };

    if (ref.current) {
      dimensions = getDimensions(ref, key);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [ref]);

  return dimensions;
};
