import React from 'react';

import PropTypes from 'prop-types';
import {useSpring, animated} from 'react-spring';

import {find, jumpIn, superFast} from '@renofi/utils/src/animation';

import {ReactComponent as LivingRoomBackground} from './img/living-room.svg';
import {ReactComponent as Sofa} from './img/sofa.svg';
import {ReactComponent as Tv} from './img/tv.svg';

const AnimatedTv = animated(Tv);
const AnimatedSofa = animated(Sofa);

const Garage = ({breakpoint}) => {
  return (
    <animated.div
      style={{
        position: 'absolute',
        top: 115,
        zIndex: 97,
        transform: 'scale(-1, 1)',
        ...useSpring({
          marginLeft: find([20, 20, 20, -80, -80, -80], breakpoint),
          config: jumpIn,
        }),
      }}>
      <LivingRoomBackground />

      <AnimatedSofa
        style={{
          position: 'absolute',
          left: 27,
          ...useSpring({
            from: {bottom: -30},
            to: {bottom: 8},
            delay: 1500,
            config: jumpIn,
          }),
        }}
      />

      <AnimatedTv
        style={{
          position: 'absolute',
          left: 29,
          top: 36,
          ...useSpring({
            from: {opacity: 0},
            to: {opacity: 1},
            delay: 1500,
            config: superFast,
          }),
        }}
      />
    </animated.div>
  );
};

Garage.propTypes = {
  breakpoint: PropTypes.number,
};

export default Garage;
