import React, {memo} from 'react';

import {noop, isNil} from 'lodash';
import PropTypes from 'prop-types';

import {digitsOnly, toCurrency} from '@renofi/utils/src/format';
import {useScreenSize, padWithZeros} from '@renofi/utils';

import {InputWrapper, Input as StyledInput} from './styled';

const SliderInput = ({
  id,
  autofocus,
  min,
  limit,
  keyIndex,
  large,
  value,
  error,
  disabled,
  css,
  onChange = noop,
  onFocus,
  onBlur = noop,
  onKeyDown,
  color,
  icon,
  currency = true,
}) => {
  const {isMobile} = useScreenSize();
  const length = String(value).length;
  const suggestedLength = length < 4 ? 5 : 6;

  function onInputFocus() {
    onFocus && onFocus(value);
  }

  function onErrorClick() {
    const newValue = padWithZeros(value, suggestedLength);
    onInputChange(String(newValue));
  }

  function onInputChange(newValue) {
    if (newValue === '' || isNil(newValue)) return onChange('');

    if (currency && !digitsOnly(newValue)) return;

    onChange(currency ? newValue && Number(newValue) : newValue);
  }

  return (
    <InputWrapper large={large} icon={icon}>
      <StyledInput
        id={id}
        currency={currency}
        numbers={currency}
        autofocus={autofocus}
        css={css}
        keyIndex={keyIndex}
        disabled={disabled}
        className={large ? 'safari-spacing-fix' : ''}
        error={error}
        large={large}
        color={color}
        onChange={onInputChange}
        onBlur={onBlur}
        onFocus={onInputFocus}
        onKeyDown={(e) => {
          onKeyDown && onKeyDown(e);
          if (e.keyCode === 13) {
            e.target.blur();
          }
        }}
        value={value}
        min={value ? min : undefined}
        limit={limit}
        errMessages={{
          min: (
            <a onClick={onErrorClick}>
              Did you mean ${toCurrency(padWithZeros(value, suggestedLength))}?
            </a>
          ),
        }}
        inputmode="numeric"
        pattern={isMobile ? '[0-9]*' : undefined}
      />
    </InputWrapper>
  );
};

SliderInput.propTypes = {
  id: PropTypes.string,
  large: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  error: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  css: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  color: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  autofocus: PropTypes.bool,
  min: PropTypes.number,
  limit: PropTypes.number,
  icon: PropTypes.string,
  keyIndex: PropTypes.number,
  currency: PropTypes.bool,
};

export default memo(SliderInput);
