import React from 'react';

import PropTypes from 'prop-types';

import {Grass, Tree, Cloud} from './styled';

const Background = ({children, css}) => {
  const urlParams = new URLSearchParams(window.location.search);
  const embedParam = urlParams.get('embed');

  if (embedParam) return children;

  return (
    <Grass css={css}>
      <Tree scale="0.7" top={370} left={536} />
      <Tree top={370} left={570} />
      <Tree top={370} right={550} />
      <Cloud top={220} right={640} />
      <Cloud top={220} left={740} />
      {children}
    </Grass>
  );
};

Background.propTypes = {
  children: PropTypes.node,
  css: PropTypes.object,
};

export default Background;
