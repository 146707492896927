import {Header} from '@renofi/components/src/Modal/styled';
import {PanelWrapper} from '@renofi/components/src/Panel/styled';

export const modalCustomStyles = {
  [Header]: {
    background: 'white',
    padding: '20px 25px 20px 25px',
  },
  [PanelWrapper]: {
    boxShadow: 'none',
    textAlign: 'center',
    margin: '-30px 0 30px 0',
  },
};
