import styled from '@emotion/styled/macro';
import {Box, Image} from 'rebass';

import {mediaQuery} from '@renofi/utils';
import {darkBlue, gray} from '@renofi/utils/src/colors';
export const Wrapper = styled(Box)(
  mediaQuery({
    boxShadow: `-1px 1px 4px 0px ${gray}`,
    position: 'relative',
    display: ['flex', 'none'],
    background: 'white',
    padding: 10,
    width: '100%',
    cursor: 'pointer',
  }),
  ({show}) => ({
    transition: 'all .30s',
    bottom: show ? 0 : '-110px!important;',
  }),
);

export const Content = styled(Box)(
  mediaQuery({
    position: 'relative',
    display: ['flex', 'none'],
    background: darkBlue,
    padding: '10px 40px 10px 80px',
    borderRadius: 100,
    color: 'white',
    minHeight: 70,
    width: '100%',
    flexDirection: 'column',
  }),
);

export const Thumb = styled(Image)(
  mediaQuery({
    width: 60,
    height: 60,
    position: 'absolute',
    left: 10,
    top: 'calc(50% - 30px)',
  }),
);
