import React from 'react';

import PropTypes from 'prop-types';

import {PanelDark} from '@renofi/components';

import {Text} from '../styled';

const MortgageBalanceBuild = () => {
  return (
    <PanelDark title="Estimated mortgage balance on the property">
      <Text mb={10}>
        The total outstanding mortgage balance on the house you are planning to
        renovate contributes to the maximum loan amount that you can borrow.
      </Text>
    </PanelDark>
  );
};

MortgageBalanceBuild.propTypes = {
  project: PropTypes.object,
};

export default MortgageBalanceBuild;
