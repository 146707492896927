import styled from '@emotion/styled/macro';
import {Text as RebassText, Box as RebassBox} from 'rebass';

import {mediaQuery} from '@renofi/utils';
import {LegalMessage} from '@renofi/components/src/FooterLinks/styled';

export const FooterWrapper = styled.footer(
  mediaQuery({
    padding: ['25px 15px 120px 15px', 0],
    background: ['white', 'none'],
  }),
);

export const FooterContent = styled.div(
  mediaQuery({
    [LegalMessage]: {
      maxWidth: 600,
    },
    marginTop: 0,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: ['column', 'row'],
    alignItems: ['center', ''],
  }),
);

export const Box = styled(RebassBox)(
  mediaQuery({
    textAlign: ['center', 'left'],
    marginTop: 30,
    marginLeft: -2,
    marginRight: -2,
  }),
  ({center, props}) =>
    mediaQuery({
      textAlign: ['center', center ? 'center' : 'left'],
      ...props,
    }),
);

export const Text = styled(RebassText)(
  mediaQuery({
    fontSize: 14,
  }),
);

export const Small = styled(RebassText)(
  mediaQuery({
    fontSize: 12,
    lineHeight: '17px',
  }),
);
