import {getEnvironmentUrl} from '@renofi/utils';

export function sendIFrameMessage(value) {
  if (!isInIframe()) return;

  const host = getEnvironmentUrl('dashboard', '4012');
  window.parent.postMessage(value, host);
}

export function isInIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export function getMaxLoanTitle(loanProduct) {
  const isHeloc = loanProduct?.productType === 'renofi_heloc';
  const isPurchaseProduct = loanProduct?.productType?.includes('purchase');

  if (isPurchaseProduct) return 'Max loan amount';
  return isHeloc ? 'Max cash amount' : 'Max cashout amount';
}
