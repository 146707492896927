import React from 'react';

import PropTypes from 'prop-types';
import {useTransition, animated} from 'react-spring';
import {Text, Box} from 'rebass';

import {superFast} from '@renofi/utils/src/animation';
import {useScreenSize} from '@renofi/utils';

import HelpBox from '../HelpBox';

import {Wrapper} from './styled';
import {ReactComponent as KeyboardIcon} from './keyboard-icon.svg';

const EnterValue = ({show}) => {
  const {isMobile} = useScreenSize();
  const AnimatedWrapper = animated(Wrapper);
  const transitions = useTransition(show, {
    from: {opacity: 0},
    enter: () => async (next) => {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      await next({opacity: 1});
    },
    leave: {opacity: 0},
    config: superFast,
  });

  if (isMobile) return null;

  return transitions(
    (props) =>
      show && (
        <AnimatedWrapper style={props}>
          <HelpBox>
            <Box>
              <KeyboardIcon />
              <Text mb={0} mt={10}>
                Start by selecting renovation type.
              </Text>
            </Box>
          </HelpBox>
        </AnimatedWrapper>
      ),
  );
};

EnterValue.propTypes = {
  show: PropTypes.bool,
};

export default EnterValue;
