import React, {memo, useEffect, useRef, useState} from 'react';

import {last, noop, isNil, isNumber, pick} from 'lodash';
import PropTypes from 'prop-types';
import {Box} from 'rebass';
import {useReactiveVar} from '@apollo/client';
import {useRouteMatch} from 'react-router-dom';

import {sendEvent} from '@renofi/analytics';
import {useDebounce, usePrevious} from '@renofi/utils';
import LogRocket from '@renofi/analytics/src/logrocket2';
import {blue} from '@renofi/utils/src/colors';
import {throttle, useDimensions} from '@renofi/utils';

import {Tooltip} from '../../Tooltips';
import {useScenario} from '../../api';
import {sendIFrameMessage} from '../../utils';
import {calculatedValuesVar} from '../../api/cache/maxLoan';

import {
  Slider as StyledSlider,
  Badge,
  Wrapper,
  FakeInput,
  Point,
  Suffix,
} from './styled';
import SliderInput from './SliderInput';
import SliderLabel from './SliderLabel';

const Slider = ({
  selected,
  autofocus,
  keyIndex,
  label = '',
  labelId = '',
  labelPrefix,
  error,
  tooltipProps = {},
  noInput,
  noSlider,
  tooltip,
  noTooltip,
  noValue,
  points = [],
  pointLabels = [],
  children,
  disabled,
  large,
  value,
  badge,
  help,
  suffix,
  inputProps,
  css,
  onChange = noop,
  onKeyDown = noop,
  onFocus = noop,
  onBlur = noop,
  icon,
  currency = true,
  ...props
}) => {
  const {
    scenario: {lead},
  } = useScenario();
  const {maxLoan, monthlyPayment} = useReactiveVar(calculatedValuesVar);
  const [dirty, setDirty] = useState(false);
  const id = label.replace && label.replace(/ /g, '-').toLowerCase();
  const usePointLabels = pointLabels.length > 0;
  const labelIndex = pointLabels.indexOf(value);
  const sliderRef = useRef(null);
  const {width} = useDimensions(sliderRef, 'slider');
  const lastPoint = last(points);
  const lastPointPosition = points && error && lastPoint / (props.max / width);
  const inputValue = usePointLabels ? pointLabels[labelIndex] : value;
  const prevValue = usePrevious(inputValue);
  const debouncedValue = useDebounce(inputValue, 1000);
  const prevDebouncedValue = usePrevious(debouncedValue);
  const {path} = useRouteMatch();
  const isPublic = path.includes('public');

  useEffect(() => {
    if (value && prevValue && prevValue !== value) setDirty(true);
  }, [value]);

  useEffect(onCompleted, [debouncedValue]);

  function onCompleted() {
    const data = getEventData();
    if (dirty && value) {
      sendEvent('Calculator/Value-Change', data);
      sendIFrameMessage(data);
    }
  }

  function shouldSnap(newValue) {
    const diff = lastPoint - newValue;
    return diff >= -props.step && diff <= props.step;
  }

  function snap() {
    onChange(lastPoint);
  }

  function throttleChange(newValue) {
    setDirty(true);
    if (!onChange) return;
    if (shouldSnap(newValue)) {
      return snap();
    }
    throttle(() => onChange(newValue));
  }

  function onSliderInputFocus(...props) {
    onFocus(...props);
  }

  function getEventData() {
    return {
      sessionURL: LogRocket.sessionURL,
      email: window.analyticsTraits?.email,
      sliderValue: inputValue,
      sliderPrevValue: prevDebouncedValue,
      sliderTitle: label,
      calculatedMaxLoanAmount: maxLoan,
      calculatedMonthlyPayment: monthlyPayment,
      lead: pick(lead, [
        'id',
        'email',
        'loanAmount',
        'afterRenovatedValue',
        'homeValue',
        'firstMortgageBalance',
        'secondMortgageBalance',
      ]),
    };
  }

  function onSliderInputChange(...props) {
    onChange(...props);
  }

  function onSliderInputBlur(...props) {
    onBlur(...props);
  }

  if (isNil(value) && labelPrefix)
    return (
      <Wrapper selected={selected} large={large} id={id} css={css}>
        <SliderLabel
          id={labelId || id}
          label={label}
          labelPrefix={labelPrefix}
          large={large}
        />
      </Wrapper>
    );

  if (isNil(value)) return null;

  return (
    <Wrapper selected={selected} large={large} id={id} css={css}>
      <SliderLabel
        id={labelId || id}
        label={label}
        labelPrefix={labelPrefix}
        large={large}
      />

      {disabled || noTooltip || tooltip || (
        <Tooltip id={label} {...tooltipProps} isPublic={isPublic} />
      )}
      {noInput ? (
        noValue || (
          <FakeInput id={id} suffix={suffix} large={large} error={error}>
            <div>{Number(inputValue).toLocaleString('en-US')}</div>
            {suffix ? <Suffix>{suffix}</Suffix> : null}
            {badge ? <Badge color={blue}>{badge}</Badge> : null}
          </FakeInput>
        )
      ) : (
        <SliderInput
          currency={currency}
          icon={icon}
          autofocus={autofocus}
          id={`${id}-input`}
          keyIndex={keyIndex}
          disabled={disabled}
          name={label}
          error={error}
          large={large}
          onChange={onSliderInputChange}
          onFocus={onSliderInputFocus}
          onBlur={onSliderInputBlur}
          onKeyDown={onKeyDown}
          value={
            currency ? (isNumber(inputValue) ? inputValue : '') : inputValue
          }
          {...inputProps}
        />
      )}

      {noSlider ? null : (
        <>
          <Box css={{position: 'relative'}}>
            {points.map((point) => {
              const left = Math.floor((point / (props.max - props.min)) * 100);
              const position = left - 18 * (left / 1000);
              return (
                <Point
                  error={error}
                  pointColor={blue}
                  key={left}
                  left={position}
                />
              );
            })}
          </Box>
          <StyledSlider
            disabled={disabled}
            ref={sliderRef}
            error={error && lastPointPosition}
            large={large}
            help={help}
            dirty={dirty}
            onSliderClick={() => {
              setDirty(true);
            }}
            value={usePointLabels ? labelIndex : Number(value)}
            onChange={throttleChange}
            thumbClassName="thumb"
            trackClassName="track"
            {...props}
          />
        </>
      )}
      {children}
    </Wrapper>
  );
};

Slider.propTypes = {
  selected: PropTypes.bool,
  autofocus: PropTypes.bool,
  label: PropTypes.string,
  labelId: PropTypes.string,
  labelPrefix: PropTypes.node,
  step: PropTypes.number,
  error: PropTypes.bool,
  tooltipProps: PropTypes.object,
  min: PropTypes.number,
  max: PropTypes.number,
  points: PropTypes.array,
  pointLabels: PropTypes.array,
  children: PropTypes.node,
  large: PropTypes.bool,
  disabled: PropTypes.bool,
  badge: PropTypes.string,
  help: PropTypes.string,
  suffix: PropTypes.string,
  noInput: PropTypes.bool,
  noSlider: PropTypes.bool,
  noTooltip: PropTypes.bool,
  noValue: PropTypes.bool,
  currency: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  css: PropTypes.object,
  onChange: PropTypes.func,
  tooltip: PropTypes.object,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  inputProps: PropTypes.object,
  keyIndex: PropTypes.number,
  icon: PropTypes.string,
};

export default memo(Slider);
