import React, {useEffect, Suspense} from 'react';

import {ThemeProvider} from '@emotion/react';
import {Switch, Route, Redirect} from 'react-router-dom';
import {Box} from 'rebass';

import {
  useFlagsStatus,
  useUnleashClient,
} from '@renofi/utils/src/feature-flags';
import {stringifyUrlParams} from '@renofi/utils/src/format';
import {Background} from '@renofi/components';
import {useUTMTags, constants, useFeatureFlagEvents} from '@renofi/utils';
import {breakpoint} from '@renofi/utils/src/mediaQuery';

import Page from '../Page';
import Intro from '../Intro';
import Public from '../Public';

const theme = {
  breakpoints: [`${breakpoint}px`],
};

const App = () => {
  const {persistUTMTags} = useUTMTags(constants.UTM_COOKIE_KEYS.renofi);
  const {flagsReady, flagsError} = useFlagsStatus();
  const client = useUnleashClient();

  useFeatureFlagEvents(client, 'Calculator');

  useEffect(() => {
    persistUTMTags();
  }, []);

  if (!flagsReady && !flagsError) {
    return null;
  }

  return (
    <Box id="app" css={{background: 'white'}}>
      <Suspense fallback={null}>
        <ThemeProvider theme={theme}>
          <Background>
            <Box css={{position: 'relative'}}>
              <Switch>
                <Route path={['/public/product/:productId', '/public']}>
                  <Public />
                </Route>
                <Route path="/new-renovation/:token?/:step?">
                  <Page allowRedirect />
                </Route>

                <Route path="/adu/:mock">
                  <Page />
                </Route>

                <Route path="/product/:mock">
                  <Page />
                </Route>

                <Route exact path="/renovation/preview">
                  <Page />
                </Route>

                <Route path="/renovation/:token?/product/:productId">
                  <Page allowRedirect />
                </Route>

                <Route path="/renovation/:token?/:step?">
                  <Page allowRedirect />
                </Route>

                <Route path="/homeowner/:page?">
                  <Intro />
                </Route>

                <Redirect to={`/homeowner/zip?${stringifyUrlParams()}`} />
              </Switch>
            </Box>
          </Background>
        </ThemeProvider>
      </Suspense>
    </Box>
  );
};

export default App;
