import styled from '@emotion/styled/macro';
import {Box, Text} from 'rebass';
import ReactSlider from 'react-slider';

import {mediaQuery} from '@renofi/utils';
import {Badge as BadgeComponent} from '@renofi/components/src/Badge';
import TextField from '@renofi/components/src/TextField';
import LabelComponent from '@renofi/components/src/Label';
import {
  blue,
  green,
  lightGray,
  lightGreen,
  lightRed,
  fadeGreen,
  fadeGray,
  fadeBlue,
  lightBlue,
  darkBlue,
} from '@renofi/utils/src/colors';

import arrowIcon from './arrow.svg';

export const Wrapper = styled(Box)(
  mediaQuery({
    position: 'relative',
    marginTop: 5,
    padding: ['15px 15px 20px 11px', '15px 25px 20px 25px'],
  }),
  ({large, selected, css}) =>
    mediaQuery({
      height: 'auto',
      ':hover': {
        background: large ? fadeBlue : fadeGreen,
      },
      background: selected ? fadeGreen : fadeGray,
      borderLeft: `solid 4px ${selected ? lightGreen : 'transparent'}`,
      ...css,
    }),
);

export const Label = styled(LabelComponent)(
  {
    paddingRight: 40,
  },
  ({large}) => ({
    fontSize: large ? 20 : 16,
  }),
);

export const Point = styled(Box)(
  {
    background: 'white',
    borderRadius: 9,
    width: 9,
    height: 9,
    border: `solid 3px ${lightGreen}`,
    position: 'absolute',
    top: 10,
    zIndex: 1,
    pointerEvents: 'none',
  },
  ({left, pointColor, error}) => ({
    borderColor: error ? lightRed : pointColor ? pointColor : lightGreen,
    left: left >= 100 ? '100%' : `${left}%`,
  }),
);

export const InputWrapper = styled.div(
  {
    position: 'relative',
    margin: '6px 0 10px 0',

    ':before': {
      zIndex: 1,
      position: 'absolute',
      borderRight: `solid 1px ${lightGray}`,
      textAlign: 'center',
      color: lightGray,
    },
  },
  ({large, icon}) => ({
    ':before': {
      content: `"${icon || '$'}"`,

      fontSize: large ? 40 : 28,
      height: large ? 60 : 50,
      width: large ? 60 : 50,
      lineHeight: large ? '64px' : '53px',
    },
  }),
);

export const Input = styled(TextField)(
  {
    padding: 0,
    fontWeight: 'bold',
    borderRadius: 8,
    background: 'white',
    ':visited, :active, :focus': {
      outline: 'none',
    },
    '@supports not (-webkit-touch-callout: none)': {
      paddingTop: 2,
    },
  },
  ({large, error, color}) => ({
    paddingLeft: large ? 75 : 60,
    fontSize: large ? 50 : 33,
    height: large ? '60px' : '50px',
    color: error ? lightRed : color ? color : 'black',
  }),
);

export const FakeInput = styled(Text)({
  position: 'relative',
  height: 45,
  paddingTop: 20,
  marginBottom: 10,
  display: 'flex',
  alignItems: 'flex-start',
  fontSize: 30,
  fontWeight: 'bold',
});

export const Badge = styled(BadgeComponent)({
  position: 'relative',
  top: -3,
  marginLeft: 7,
});

export const Suffix = styled.sup({
  position: 'relative',
  top: -5,
  marginLeft: 5,
  fontSize: 19,
});

export const Slider = styled(ReactSlider)(
  {
    color: green,
    height: 25,
    '.thumb': {
      outline: 'none',
      cursor: 'pointer',
      width: 36,
      height: 30,
      background: `#0CA61D url(${arrowIcon}) no-repeat 12px 10px`,
      borderRadius: 15,
      boxShadow: '0px 2px 4px 0px rgba(0,0,0,0.2)',
      ':active': {
        backgroundPosition: '13px 11px',
        marginTop: -1,
        marginLeft: -1,
        width: 38,
        height: 32,
      },
    },
    '.track': {
      top: 12,
      height: 10,
    },
    '.track-0': {
      height: 4,
    },
    '.track-1': {
      background: `#e0e0e0`,
      height: 4,
    },
  },
  ({large, error, help, dirty}) => ({
    '.thumb': {
      backgroundColor: large ? blue : green,
      ':after': help
        ? mediaQuery({
            position: 'absolute',
            top: 'calc(50% + 32px)',
            left: ['calc(50% - 35px)', 'calc(50% - 75px)'],
            content: `"${help}"`,
            width: 120,
            color: 'white',
            textAlign: 'center',
            background: darkBlue,
            padding: [10, 15],
            borderRadius: 10,
            transition: 'opacity .1s',
            opacity: help && !dirty ? 1 : 0,
          })
        : {},
      ':before': help
        ? {
            content: `" "`,
            position: 'absolute',
            borderLeft: '10px solid transparent',
            borderRight: '10px solid transparent',
            borderBottom: `10px solid ${darkBlue}`,
            top: 'calc(50% + 24px)',
            left: 'calc(50% - 10px)',
            transition: 'opacity .05s',
            opacity: help && !dirty ? 1 : 0,
          }
        : {},
    },
    '.track-0': {
      background: error
        ? `linear-gradient(90deg, ${lightBlue} 0%, ${lightBlue} ${error}px, ${lightRed} ${error}px);`
        : large
          ? lightBlue
          : lightGreen,
    },
  }),
);
