import {makeVar} from '@apollo/client';

// Reactive variables
const newHomeownerVar = makeVar({
  firstName: '',
  lastName: '',
  email: '',
  city: '',
  phone: '',
  hasNewsletterConsent: false,
  creditScore: '',
  state: '',
  zipCode: '',
  afterRenovatedValue: null,
  homeValue: null,
  firstMortgageBalance: null,
  secondMortgageBalance: null,
  firstMortgageRate: null,
  loanAmountDesiredForRenovation: null,
  stageOfPurchase: null,
  loanSubject: 'renovate_current_home',
  propertyPurchasePrice: null,
  renovationCost: null,
  estimatedDownpayment: null,
});

// Setter
export const setNewHomeowner = (newHomeowner) => {
  return newHomeownerVar({
    ...newHomeownerVar(),
    ...newHomeowner,
  });
};

// Apollo field policy
export const newHomeownerPolicy = {
  read() {
    return newHomeownerVar();
  },
};
