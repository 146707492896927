import styled from '@emotion/styled/macro';
import {Text as RebassText, Link as RebassLink} from 'rebass';

import {blue} from '@renofi/utils/src/colors';

export const Text = styled(RebassText)({
  lineHeight: '20px',
  fontSize: 14,
});

export const Link = styled(RebassLink)({
  lineHeight: '10px',
  fontSize: 14,
  color: blue,
  '&:hover': {
    textDecoration: 'none',
  },
});

export const Strong = styled(RebassText)({
  fontSize: 14,
  fontWeight: 'bold',
});
