import React, {memo} from 'react';

import PropTypes from 'prop-types';
import {useSpring, animated} from 'react-spring';

import {find, jumpIn, superFast} from '@renofi/utils/src/animation';

import {ReactComponent as Background} from './img/bedroom.svg';
import {ReactComponent as Lamp} from './img/lamp.svg';
import {ReactComponent as Bed} from './img/double-bed.svg';
import {ReactComponent as Wardrobe} from './img/wardrobe.svg';

const AnimatedBackground = animated(Background);
const AnimatedBed = animated(Bed);
const AnimatedLamp = animated(Lamp);
const AnimatedWardrobe = animated(Wardrobe);

const Bedroom = ({breakpoint, isReturning}) => {
  return (
    <animated.div
      id="bedroom"
      style={{
        overflow: 'hidden',
        position: 'absolute',
        zIndex: 99,
        top: 60,
        left: -70,
      }}>
      <AnimatedBackground
        style={{
          ...useSpring({
            marginLeft: find([70, 70, 70, 70, 70, 70, 10], breakpoint),
            config: isReturning ? superFast : jumpIn,
          }),
        }}
      />
      <AnimatedLamp
        style={{
          position: 'absolute',
          top: 15,
          ...useSpring({
            left: find([108, 108, 108, 108, 108, 108, 80], breakpoint),
            config: superFast,
          }),
        }}
      />
      <AnimatedBed
        style={{
          position: 'absolute',
          bottom: 4,
          ...useSpring({
            from: {bottom: -100},
            to: {bottom: 4},
            delay: 1500,
            config: jumpIn,
          }),
          ...useSpring({
            left: find([74, 74, 74, 74, 74, 74, 70], breakpoint),
            config: superFast,
          }),
        }}
      />
      <AnimatedWardrobe
        style={{
          position: 'absolute',
          bottom: 5,
          left: 20,
          ...useSpring({
            opacity: find([0, 0, 0, 0, 0, 0, 1], breakpoint),
            config: isReturning ? {duration: 0} : jumpIn,
          }),
        }}
      />
    </animated.div>
  );
};

Bedroom.propTypes = {
  breakpoint: PropTypes.number,
  isReturning: PropTypes.bool,
};

export default memo(Bedroom);
