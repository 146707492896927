import styled from '@emotion/styled/macro';
import {Box} from 'rebass';

import {mediaQuery} from '@renofi/utils';
import {LegalMessage} from '@renofi/components/src/FooterLinks/styled';

export const Container = styled(Box)(
  mediaQuery({
    boxSizing: ['border-box', 'content-box'],
    padding: [0, 40],
    margin: '0 auto',
    textAlign: 'center',
    [LegalMessage]: {
      fontWeight: 300,
      letterSpacing: -0.1,
    },
  }),
  ({width, bg}) =>
    mediaQuery({
      width: ['100%', width || 944],
      minHeight: ['100vh', 'auto'],
      background: bg || 'none',
    }),
);
