import React from 'react';

import PropTypes from 'prop-types';
import {animated, useSpring} from 'react-spring';

import {easyIn, jumpIn, find} from '@renofi/utils/src/animation';

import {ReactComponent as BaseBackground} from './img/base.svg';

const Base = ({breakpoint, isReturning}) => {
  return (
    <animated.div
      id="base"
      style={{
        position: 'absolute',
        zIndex: 100,
      }}>
      <BaseBackground style={{position: 'absolute'}} />

      <animated.div
        style={{
          position: 'absolute',
          top: 141,
          left: 185,
          ...useSpring({
            left: find([185, 185, 185, 185, 275, 275], breakpoint),
            config: isReturning ? easyIn : jumpIn,
          }),
        }}>
        <svg width="5px" height="60px" viewBox="0 0 5 60">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <animated.rect
              id="Garage-wall"
              fill="#CCCCCC"
              x="0"
              y="0"
              width="5"
              height="90"
            />
          </g>
        </svg>
      </animated.div>
    </animated.div>
  );
};

Base.propTypes = {
  breakpoint: PropTypes.number,
  isReturning: PropTypes.bool,
};

export default Base;
