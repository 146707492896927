import React from 'react';

import PropTypes from 'prop-types';

import {InfoTooltip} from '@renofi/components';

import MaxLoan from './MaxLoan';
import CurrentValue from './CurrentValue';
import EstimatedMonthlyPayment from './EstimatedMonthlyPayment';
import LoanAmount from './LoanAmount';
import LoanAmountAdu from './LoanAmountAdu';
import LoanAmountBuild from './LoanAmountBuild';
import LoanDetails from './LoanDetails';
import MortgageBalance from './MortgageBalance';
import MortgageBalanceDownpaymant from './MortgageBalanceDownpaymant';
import MortgageBalanceAdu from './MortgageBalanceAdu';
import CurrentValueBuild from './CurrentValueBuild';
import LoanTerm from './LoanTerm';
import Rate from './Rate';
import ExpectedValue from './ExpectedValue';
import ExpectedValueAdu from './ExpectedValueAdu';
import ExpectedValueBuild from './ExpectedValueBuild';
import MortgageBalanceBuild from './MortgageBalanceBuild';

const tooltipsMap = {
  'Current home value': CurrentValue,
  'Estimated current property value': CurrentValueBuild,
  'Current home value where the ADU will be located': CurrentValue,
  'Estimated minimum monthly payment': EstimatedMonthlyPayment,
  'Loan amount': LoanAmount,
  'Loan amount needed for the renovation': LoanAmount,
  'Loan amount needed for the build': LoanAmountBuild,
  'Cash amount for renovation': LoanAmount,
  'Cashout amount for renovation': LoanAmount,
  'Loan amount needed for the ADU': LoanAmountAdu,
  'Loan details': LoanDetails,
  'Total outstanding mortgage balance': MortgageBalance,
  'Total outstanding mortgage balance on the property': MortgageBalance,
  'Estimated mortgage balance on the property': MortgageBalanceBuild,
  'Estimated mortgage balance on the property with ADU': MortgageBalanceAdu,
  'Estimated mortgage balance after downpayment': MortgageBalanceDownpaymant,
  'Your max loan amount': MaxLoan,
  'Max cash amount': MaxLoan,
  'Max cashout amount': MaxLoan,
  'Loan term': LoanTerm,
  'Interest rate': Rate,
  'Expected home value post renovation': ExpectedValue,
  'Expected home value post ADU completion': ExpectedValueAdu,
  'Expected property value upon completion': ExpectedValueBuild,
};

const Tooltip = ({id, css, ...props}) => {
  const TooltipContent = tooltipsMap[id];

  if (!TooltipContent) {
    return null;
  }

  return (
    <InfoTooltip dark id={id} css={css}>
      <TooltipContent {...props} />
    </InfoTooltip>
  );
};

Tooltip.propTypes = {
  id: PropTypes.string,
  css: PropTypes.object,
};

export default Tooltip;
