import {SELF_REPORTED_ORIGINS} from './const';

export const isSelfReportedB2b2cLead = (lead) => {
  return [
    SELF_REPORTED_ORIGINS.CONTRACTOR,
    SELF_REPORTED_ORIGINS.LOAN_OFFICER,
    SELF_REPORTED_ORIGINS.CONSTRUCTION_PROFESSIONAL,
    SELF_REPORTED_ORIGINS.FINANCIAL_INSTITUTION,
    SELF_REPORTED_ORIGINS.RENOFI_PARTNER_WEBSITE,
    SELF_REPORTED_ORIGINS.REALTOR,
  ].includes(lead.selfReportedOriginType);
};
