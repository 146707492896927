import React, {useEffect} from 'react';

import ReactDOM from 'react-dom';
import {Box} from 'rebass';
import PropTypes from 'prop-types';
import {Flex} from 'rebass';
import {FixedBottom} from 'react-fixed-bottom';

const StickyBottom = ({
  children,
  height = 76,
  boxProps,
  root = 'root',
  skipPlaceholder,
}) => {
  useEffect(() => {
    document.body.classList.add('sticky-footer');
    return () => document.body.classList.remove('sticky-footer');
  }, []);

  return (
    <>
      {skipPlaceholder ? null : (
        <Box height={height} bg="white" {...boxProps} />
      )}
      {ReactDOM.createPortal(
        <FixedBottom>
          <Flex
            data-testid="sticky-footer"
            css={{
              zIndex: 100111,
              width: '100%',
              height: height,
              bottom: '0px !important',
              boxShadow: '0px 0 5px #00000038',
              padding: 10,
              alignItems: 'center',
              justifyContent: 'center',
              background: 'white',
            }}>
            {children}
          </Flex>
        </FixedBottom>,
        document.getElementById(root),
      )}
    </>
  );
};

StickyBottom.propTypes = {
  children: PropTypes.node,
  height: PropTypes.number,
  boxProps: PropTypes.object,
  skipPlaceholder: PropTypes.bool,
  root: PropTypes.string,
};

export default StickyBottom;
