import React from 'react';

import PropTypes from 'prop-types';
import {Box} from 'rebass';
import {isNil} from 'lodash';

import {LoanAmountError} from '@renofi/components';
import {useScreenSize} from '@renofi/utils';

import {Tooltip} from '../../../Tooltips';
import {Slider} from '../../../components';
import {customSliderStyles} from '../styled';
import useLabels from '../../../utils/useLabels';

const FormInputs = ({
  lead,
  onChange,
  setDirty,
  isMinLoanInvalid,
  selected,
  setSelected,
}) => {
  const {isDesktop} = useScreenSize();
  const labels = useLabels(lead);
  const common = {
    large: isDesktop,
    css: customSliderStyles,
    noSlider: true,
  };

  return (
    <>
      <Slider
        inputProps={{min: 10000, limit: 9}}
        keyIndex={2}
        selected={selected === 2}
        label={labels.homeValue}
        onFocus={() => setSelected(2)}
        onChange={(value) => onChange({homeValue: value})}
        value={lead.homeValue || ''}
        {...common}
      />
      <Slider
        keyIndex={3}
        inputProps={{limit: 9}}
        selected={selected === 3}
        label={labels.loanAmountDesiredForRenovation}
        onFocus={() => setSelected(3)}
        onChange={(value) => onChange({loanAmountDesiredForRenovation: value})}
        onBlur={() => setDirty(true)}
        value={lead.loanAmountDesiredForRenovation || ''}
        error={isMinLoanInvalid()}
        {...common}>
        <Box mt="-5px" pb="5px">
          <LoanAmountError
            show={isMinLoanInvalid()}
            analyticsPrefix="Calculator"
          />
        </Box>
      </Slider>

      <Slider
        keyIndex={4}
        inputProps={{min: 10000, limit: 9}}
        selected={selected === 4}
        label={labels.afterRenovatedValue}
        onFocus={() => setSelected(4)}
        onChange={(value) => onChange({afterRenovatedValue: value})}
        value={lead.afterRenovatedValue || ''}
        {...common}
      />

      <Slider
        keyIndex={5}
        tooltip={
          lead.loanSubject === 'renovate_or_build_adu' ? (
            <Tooltip id="Estimated mortgage balance on the property with ADU" />
          ) : undefined
        }
        inputProps={{limit: 9}}
        selected={selected === 5}
        label={labels.firstMortgageBalance}
        onFocus={() => setSelected(5)}
        onBlur={() => setSelected(5)}
        onChange={(value) => onChange({firstMortgageBalance: value})}
        value={
          !isNil(lead.firstMortgageBalance) ? lead.firstMortgageBalance : ''
        }
        {...common}
      />
    </>
  );
};

FormInputs.propTypes = {
  lead: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  setDirty: PropTypes.func.isRequired,
  isMinLoanInvalid: PropTypes.func.isRequired,
  selected: PropTypes.number,
  setSelected: PropTypes.func.isRequired,
};

export default FormInputs;
