import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {Flex, Text} from 'rebass';

import {sendEvent} from '@renofi/analytics';
import {darkGray, gray, blue} from '@renofi/utils/src/colors';

import {ReactComponent as CalculatorImage} from './people-and-calculator.svg';

const SuccessModal = () => {
  const copies = [
    'Filtering lenders by location',
    'Refining interest rates',
    'Calculating minimum monthly payment',
    'Selecting loan type',
    'Selecting example lender',
  ];
  const [index, setIndex] = useState(0);

  useEffect(() => {
    sendEvent('Calculator/New-Homeowner/Final-Details-Success');
    // eslint-disable-next-line no-undef
    const interval = setInterval(() => {
      setIndex((index) => (index >= copies.length - 1 ? index : index + 1));
    }, 900);
    // eslint-disable-next-line no-undef
    return () => clearInterval(interval);
  }, []);

  return (
    <Flex
      pt={50}
      pb={20}
      px={[15, 150]}
      alignItems="center"
      flexDirection="column">
      <CalculatorImage style={{marginLeft: 35}} />
      <Text
        mt={25}
        fontSize={24}
        textAlign="center"
        lineHeight="28px"
        color={darkGray}>
        One moment while we personalize the calculator...
      </Text>

      <Text color={gray} fontSize={14} mt={35}>
        Status: <span style={{color: blue}}>{copies[index] || copies[0]}</span>
      </Text>
    </Flex>
  );
};

SuccessModal.propTypes = {
  onClose: PropTypes.func,
};

export default SuccessModal;
