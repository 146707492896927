import React, {memo} from 'react';

import PropTypes from 'prop-types';
import {useSpring, animated} from 'react-spring';

import {easyIn, jumpIn, superFast, find} from '@renofi/utils/src/animation';

import {ReactComponent as Background} from './img/bedroom.svg';
import {ReactComponent as Toilet} from './img/toilet.svg';
import {ReactComponent as Mirror} from './img/mirror.svg';
import {ReactComponent as Unit} from './img/bathroom-unit.svg';
import {ReactComponent as Bath} from './img/bath.svg';
import {ReactComponent as Shower} from './img/shower.svg';

const AnimatedBackground = animated(Background);
const AnimatedToilet = animated(Toilet);
const AnimatedMirror = animated(Mirror);
const AnimatedUnit = animated(Unit);
const AnimatedBath = animated(Bath);
const AnimatedShower = animated(Shower);

const Bathroom = ({breakpoint, isReturning}) => {
  return (
    <animated.div
      id="bathroom"
      style={{
        overflow: 'hidden',
        position: 'absolute',
        zIndex: 99,
        top: 60,
        left: 96,
        width: 200,
      }}>
      <AnimatedBackground
        style={{
          ...useSpring({
            transform: 'scaleX(-1)',
            marginLeft: find([-46, -46, -46, -46, -46, -5], breakpoint),
            config: isReturning ? superFast : jumpIn,
          }),
        }}
      />
      <AnimatedToilet
        style={{
          position: 'absolute',
          bottom: 3,
          ...useSpring({
            from: {bottom: -100},
            to: {bottom: 3},
            delay: 1300,
            config: jumpIn,
          }),
          ...useSpring({
            left: find([20, 20, 20, 20, 20, 25], breakpoint),
            config: superFast,
          }),
        }}
      />
      <AnimatedMirror
        style={{
          position: 'absolute',
          top: 29,
          ...useSpring({
            from: {top: -100},
            to: {top: 29},
            delay: 1400,
            config: easyIn,
          }),
          ...useSpring({
            left: find([42, 42, 42, 42, 42, 55], breakpoint),
            config: superFast,
          }),
        }}
      />
      <AnimatedUnit
        style={{
          position: 'absolute',
          ...useSpring({
            from: {bottom: -100},
            to: {bottom: -9},
            delay: 1400,
            config: easyIn,
          }),
          ...useSpring({
            left: find([43, 43, 43, 43, 43, 56], breakpoint),
            config: superFast,
          }),
        }}
      />
      <AnimatedBath
        style={{
          position: 'absolute',
          top: 38,
          left: 86,
          ...useSpring({
            opacity: find([0, 0, 0, 0, 0, 1], breakpoint),
            config: superFast,
          }),
        }}
      />
      <div
        style={{
          position: 'absolute',
          overflow: 'hidden',
          width: 20,
          top: 38,
          left: 71,
        }}>
        <AnimatedShower
          style={{
            ...useSpring({
              from: {marginLeft: 100},
              to: {marginLeft: 0},
              delay: 1400,
              config: easyIn,
            }),
            ...useSpring({
              opacity: find([1, 1, 1, 1, 1, 0], breakpoint),
              config: {duration: 100},
            }),
          }}
        />
      </div>
    </animated.div>
  );
};

Bathroom.propTypes = {
  breakpoint: PropTypes.number,
  isReturning: PropTypes.bool,
};

export default memo(Bathroom);
