import React from 'react';

import PropTypes from 'prop-types';

import {PanelDark} from '@renofi/components';

import {Text} from '../styled';

const MortgageBalance = () => {
  return (
    <PanelDark title="Total outstanding mortgage balance">
      <Text mb={10}>
        The total outstanding mortgage balance on the house you are planning to
        renovate contributes to the maximum loan amount that you can borrow.
      </Text>
      <Text>
        Check your mortgage statement or online bank account to find the most
        recent outstanding balance.{' '}
      </Text>
    </PanelDark>
  );
};

MortgageBalance.propTypes = {
  project: PropTypes.object,
};

export default MortgageBalance;
