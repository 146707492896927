import React from 'react';

import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import {Box, Text} from 'rebass';

import {leeImage} from '@renofi/components';

import useScenario from '../api/hooks/useScenario';

import {Wrapper, Content, Thumb} from './styled';

const ContactButton = ({whatNextRef, show}) => {
  const {scenario} = useScenario();
  const {lead} = scenario;

  function scrollToWhatNext() {
    window.scrollTo({
      behavior: 'smooth',
      top: whatNextRef.current.getBoundingClientRect().top + window.scrollY,
    });
  }

  return ReactDOM.createPortal(
    <Wrapper
      show={show}
      style={{zIndex: 100, width: '100%', position: 'fixed', bottom: '0px'}}>
      <Content onClick={scrollToWhatNext}>
        <Thumb src={leeImage} />

        <Box>
          <Text color="white" fontSize={20}>
            What’s next{lead?.name ? ` ${lead.name}` : ''}?
          </Text>

          <Text
            css={{
              paddingTop: 5,
              lineHeight: '17px',
              fontSize: 14,
              color: '#80CFFF',
            }}>
            I’m Lee, your dedicated RenoFi Advisor
          </Text>
        </Box>
      </Content>
    </Wrapper>,
    document.getElementById('root'),
  );
};

ContactButton.propTypes = {
  whatNextRef: PropTypes.object,
  show: PropTypes.bool,
};

export default ContactButton;
