export default ({hasTerms, ...params}) => {
  return hasTerms ? getLoanByTerm(params) : getLoan(params);
};

const getLoan = ({loanAmountDesiredForRenovation = 0, selectedRate = 0}) => {
  return (loanAmountDesiredForRenovation * selectedRate) / 1200;
};

const getLoanByTerm = ({
  loanAmountDesiredForRenovation = 0,
  secondMortgageBalance = 0,
  selectedRate = 0,
  selectedTerm = 0,
}) => {
  const payments = selectedTerm * 12;
  const monthlyRate = selectedRate / 100 / 12;
  return (
    (monthlyRate * (loanAmountDesiredForRenovation + secondMortgageBalance)) /
    (1 - (1 + monthlyRate) ** (-1 * payments))
  );
};
