export default function usePrimaryAndSecondaryProducts(
  products,
  maxProducts = 3,
) {
  const primaryOptions = products
    .filter((product) => product.productType !== 'purchase_loan')
    .slice(0, maxProducts);
  const secondaryOptions = products.filter(
    (product) => product.productType === 'purchase_loan',
  );

  return {primaryOptions, secondaryOptions};
}
