import React from 'react';

import PropTypes from 'prop-types';

import {PanelDark} from '@renofi/components';

import {Text} from '../styled';

const helocInterestOnlyText =
  ' This loan product is a Home Equity Line of Credit. The monthly payment during the draw period is interest only.';

const EstimatedMonthlyPayment = ({interestOnly, productType, lender}) => {
  if (interestOnly) {
    return (
      <PanelDark title="Estimated minimum monthly payment">
        <Text mb={10}>
          The estimated minimum monthly payment is calculated on{' '}
          <strong>Loan amount</strong> and <strong>Interest rate</strong>.
          {productType === 'renofi_heloc' && helocInterestOnlyText}
        </Text>
      </PanelDark>
    );
  }

  return (
    <PanelDark title="Estimated minimum monthly payment">
      <Text mb={10}>
        The estimated monthly payment is calculated on{' '}
        <strong>Loan amount</strong>, <strong>Loan term</strong> and{' '}
        <strong>Interest rate</strong>.
      </Text>
      {lender?.code === 'ardent' && (
        <Text mb={10}>
          Estimated monthly payment does not include any fees that may be
          present during the renovation period.
        </Text>
      )}
    </PanelDark>
  );
};

EstimatedMonthlyPayment.propTypes = {
  scenario: PropTypes.object,
};

export default EstimatedMonthlyPayment;
