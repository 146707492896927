import React, {memo} from 'react';

import PropTypes from 'prop-types';

import {FooterLinks, Toggle} from '@renofi/components';

import {useScenario} from '../api';

import {FooterWrapper, FooterContent, Box, Text, Small} from './styled';

const Footer = () => {
  // Apollo
  const {
    scenario: {lender, lead},
  } = useScenario();
  const {inLicensedState} = lead || {};

  return (
    <FooterWrapper>
      <Small pt={30}>
        <strong>Disclaimer</strong>: This is not an official loan estimate. Get
        an official loan estimate before choosing a loan. Your actual rate,
        payment, and costs could be higher. RenoFi is not a lender, rather we’ve
        partnered with lenders that leverage RenoFi’s technology to seamlessly
        provide RenoFi Loans. The rates, monthly payments, and terms shown are
        illustrative of products offered by RenoFi partner lenders but may not
        be available in all locations - apply with a RenoFi partner lender to
        obtain an official loan estimate.
      </Small>

      <FooterContent>
        <Box>
          <Toggle show={lender?.name && !inLicensedState}>
            <Text>Example RenoFi Loan Lender</Text>
            <Text color="black" fontSize={18}>
              {lender?.name}
            </Text>
          </Toggle>
        </Box>

        <FooterLinks />
      </FooterContent>
    </FooterWrapper>
  );
};

Footer.propTypes = {
  project: PropTypes.object,
};

export default memo(Footer);
