const ONE_HOUR = 60 * 60 * 1000;
const FULL_DAY = 24 * 60 * 60 * 1000;

export function isDateOld(date, diff = ONE_HOUR) {
  if (typeof date === 'string') {
    return new Date() - new Date(date) > diff;
  }
  return new Date() - date > diff;
}

export function isOneHourOld(date) {
  return isDateOld(date, ONE_HOUR);
}

export function is24HoursOld(date) {
  return isDateOld(date, FULL_DAY);
}

export function getTimeDiff(date1, date2) {
  const diff = (date2 - date1) / 1000 / 60;
  return diff.toFixed(0);
}

export function getSecondsDiff(date1, date2) {
  const diff = (date2 - date1) / 1000;
  return diff.toFixed(0);
}

export function formatAMPM(date) {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  return hours + ':' + minutes + ampm;
}

// Output like "1:01" or "4:03:59" or "123:03:59"
export function humanTimeFormat(duration) {
  const hrs = ~~(duration / 3600);
  const min = ~~((duration % 3600) / 60);
  const sec = ~~duration % 60;
  let output = '';

  if (hrs > 0) {
    output += '' + hrs + ':' + (min < 10 ? '0' : '');
  }

  output += '' + min + ':' + (sec < 10 ? '0' : '');
  output += '' + sec;

  return output;
}
