import React from 'react';

import PropTypes from 'prop-types';
import {useSpring, animated} from 'react-spring';

import {jumpIn, useBreakpoint} from '@renofi/utils/src/animation';

import Base from './Base';
import Garage from './Garage';
import LivingRoom from './LivingRoom';
import Bathroom from './Bathroom';
import Bedroom from './Bedroom';
import Kitchen from './Kitchen';
import DiningRoom from './DiningRoom';
import Attic from './Attic';

const AnimatedHome = ({loanAmount, maxLoanAmount = 1}) => {
  const [breakpoint, isReturning] = useBreakpoint(loanAmount, maxLoanAmount);

  return (
    <>
      <animated.div
        style={{
          transform: 'scale(0.6)',
          position: 'relative',
          marginLeft: 80,
          marginTop: -33,
          ...useSpring({
            from: {marginTop: 210},
            to: {marginTop: -33},
            delay: 700,
            config: jumpIn,
          }),
        }}>
        <div
          style={{
            position: 'absolute',
            zIndex: 1,
            background: 'white',
            top: 75,
            left: 10,
            width: 170,
            height: 60,
          }}
        />
        <Base breakpoint={breakpoint} isReturning={isReturning} />
        <Attic breakpoint={breakpoint} isReturning={isReturning} />
        <DiningRoom />
        <Kitchen breakpoint={breakpoint} isReturning={isReturning} />
        <Bedroom breakpoint={breakpoint} isReturning={isReturning} />
        <Bathroom breakpoint={breakpoint} isReturning={isReturning} />
        <LivingRoom breakpoint={breakpoint} />
        <Garage breakpoint={breakpoint} isReturning={isReturning} />
      </animated.div>
    </>
  );
};

AnimatedHome.propTypes = {
  loanAmount: PropTypes.number,
  maxLoanAmount: PropTypes.number,
};

export default AnimatedHome;
