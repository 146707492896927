import styled from '@emotion/styled/macro';
import {Flex, Text as RebassText} from 'rebass';

import {lightGreen} from '@renofi/utils/src/colors';

export const Wrapper = styled(Flex)(
  {
    borderRadius: 2,
    background: lightGreen,
    display: 'inline-block',
    justifyContent: 'center',
    alignItems: 'center',
  },
  ({color, large, xLarge, css}) => ({
    background: color ? color : lightGreen,
    padding: large ? '3px 6px' : xLarge ? '8px' : '0px 4px',
    height: large ? 25 : xLarge ? 35 : 18,
    ...css,
  }),
);

export const Text = styled(RebassText)(({textColor, textTransform}) => ({
  color: textColor || 'white',
  textTransform,
  margin: 0,
  lineHeight: 'auto',
  height: 15,
}));
