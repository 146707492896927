import React, {useEffect} from 'react';

import PropTypes from 'prop-types';
import {useReactiveVar} from '@apollo/client';
import {isNumber} from 'lodash';
import {Flex} from 'rebass';

import {useMaxValues} from '@renofi/utils';
import {Checkbox} from '@renofi/components';

import {Slider} from '../../../components';
import {useNewHomeownerCache, useScenario} from '../../../api';
import {
  mortgageBalanceVar,
  setMortgageBalance,
} from '../../../api/cache/mortgageBalance';
import {useUSAllianceMock} from '../../../api/hooks';

const SecondMortgageSlider = ({onChange, value, ...props}) => {
  // Apollo
  const {scenario} = useScenario();
  const {isUSAllianceMock} = useUSAllianceMock();
  const {secondMortgageBalance} = useReactiveVar(mortgageBalanceVar);
  const {newHomeowner} = useNewHomeownerCache();
  const lead = scenario.lead || newHomeowner;
  // App
  const maxValues = useMaxValues(lead);

  useEffect(() => {
    setMortgageBalance({
      secondMortgageBalance: isNumber(value) ? value : null,
    });
    onSliderChange(value);
  }, [value]);

  function onCheckboxChange() {
    const newBalance = isNumber(secondMortgageBalance)
      ? null
      : lead.secondMortgageBalance || 0;
    setMortgageBalance(newBalance);
    onChange({
      secondMortgageBalance: newBalance,
    });
  }

  function onSliderChange(newValue) {
    setMortgageBalance({secondMortgageBalance: newValue});
    onChange({secondMortgageBalance: newValue});
  }

  if (
    lead.loanSubject !== 'renovate_current_home' &&
    lead.loanSubject !== 'renovate_or_build_adu' &&
    !isUSAllianceMock &&
    (!lead.secondMortgageBalance || !lead.secondaryMortgagePayment)
  ) {
    return null;
  }

  return (
    <>
      <Slider
        labelPrefix={
          <Flex onClick={onCheckboxChange} css={{cursor: 'pointer'}}>
            <Checkbox
              id="second-mortgage-balance-input"
              m="-1px 16px 0 0"
              name="second-mortgage-balance"
              checked={isNumber(secondMortgageBalance)}
              onChange={onCheckboxChange}
            />
          </Flex>
        }
        label="I have a 2nd mortgage balance"
        labelId="second-mortgage-balance"
        noTooltip
        step={5000}
        noSlider={!isNumber(secondMortgageBalance)}
        noInput={!isNumber(secondMortgageBalance)}
        noValue={!isNumber(secondMortgageBalance)}
        min={0}
        max={maxValues.firstMortgageBalance}
        onChange={(value) => onSliderChange(value)}
        value={value}
        {...props}
      />
    </>
  );
};

SecondMortgageSlider.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
};

export default SecondMortgageSlider;
