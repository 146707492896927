import React from 'react';

import {PanelDark} from '@renofi/components';

import {Text} from '../styled';

const MortgageBalanceDownpaymant = () => {
  return (
    <PanelDark title="Estimated mortgage balance after downpayment">
      <Text mb={10}>
        The estimated mortgage balance after downpayment on the property you’re
        looking to acquire and renovate contributes to the maximum loan amount
        that you can borrow.
      </Text>
      <Text>If you’re buying cash, you can enter $0.</Text>
    </PanelDark>
  );
};

export default MortgageBalanceDownpaymant;
