import React from 'react';

import PropTypes from 'prop-types';

import {PanelDark, Toggle} from '@renofi/components';

import {Text, Strong, Link} from '../styled';

const CurrentValue = ({isPublic}) => {
  return (
    <PanelDark title="Current home value">
      <Text mb={10}>
        What have homes like yours sold for in your area? If you&apos;re not
        sure, check out{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.redfin.com">
          Redfin
        </a>{' '}
        or{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.zillow.com">
          Zillow
        </a>{' '}
        for an estimate of what your home could be worth.
      </Text>
      <Text mb={10}>
        The current value of your home contributes to the maximum loan amount
        that you can borrow.{' '}
      </Text>
      <Toggle show={!isPublic}>
        <Strong>Related reading from our blog</Strong>
        <Link
          target="_blank"
          href="https://www.renofi.com/blog/how-do-home-appraisals-really-work/">
          How do home appraisals really work?
        </Link>
      </Toggle>
    </PanelDark>
  );
};

CurrentValue.propTypes = {
  project: PropTypes.object,
};

export default CurrentValue;
