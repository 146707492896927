import React from 'react';

import PropTypes from 'prop-types';
import {Text, Box, Flex} from 'rebass';

import Button from '../Button';
import HelpBox from '../HelpBox';

import {ReactComponent as CalculatorIcon} from './calculator-icon.svg';
import {ReactComponent as PlusIcon} from './plus-icon-cirlce.svg';
import {Wrapper} from './styled';

const AddDetails = ({onClick, shake}) => {
  return (
    <Wrapper shake={shake}>
      <HelpBox>
        <Box>
          <CalculatorIcon />
          <Text mb={20} mt={20}>
            Additional details are required to calculate estimated monthly
            payments and interest rates.
          </Text>
          <Button onClick={onClick}>
            <Flex alignItems="center">
              <PlusIcon
                style={{
                  marginRight: 10,
                  marginLeft: -15,
                }}
              />
              Click to add Details
            </Flex>
          </Button>
        </Box>
      </HelpBox>
    </Wrapper>
  );
};

AddDetails.propTypes = {
  onClick: PropTypes.func.isRequired,
  shake: PropTypes.bool,
};

export default AddDetails;
