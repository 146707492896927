import styled from '@emotion/styled/macro';
import {Flex} from 'rebass';

import {fadeGray, fadeBlue} from '@renofi/utils/src/colors';

export const TitleBar = styled(Flex)({
  padding: '15px 20px',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: fadeBlue,
  margin: '20px -20px 20px -20px',
});

export const Footer = styled(Flex)({
  padding: 20,
  borderRadius: '0 0 4px 4px',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: fadeGray,
  margin: '20px -20px -20px -20px',
});
