import React from 'react';

import PropTypes from 'prop-types';
import {Flex} from 'rebass';

import {Label} from '../styled';

const SliderLabel = ({id, label, large, labelPrefix}) => {
  return (
    <Flex>
      {labelPrefix}
      {label && (
        <Label htmlFor={`${id}-input`} large={large}>
          {label}
        </Label>
      )}
    </Flex>
  );
};

SliderLabel.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  large: PropTypes.bool,
  labelPrefix: PropTypes.any,
};

export default SliderLabel;
