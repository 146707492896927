import styled from '@emotion/styled/macro';
import {Box} from 'rebass';

import {mediaQuery} from '@renofi/utils';
import {fadeGray} from '@renofi/utils/src/colors';
import CloudIcon from '@renofi/components/src/Icons/cloud.svg';

export const Section = styled(Box)(
  mediaQuery({
    width: '100%',
    '&:first-of-type': {
      paddingRight: [0, 5],
      minWidth: '45%',
      maxWidth: ['100%', '45%'],
    },
  }),
  ({embed}) => ({
    padding: embed ? 0 : '5px 0 0 0',
  }),
);

export const AnimatedHomeWrapper = styled(Box)(
  mediaQuery({
    background: `url(${CloudIcon}) 40px 0 no-repeat, url(${CloudIcon}) 330px 20px no-repeat`,
    backgroundSize: 70,
    height: 134,
    marginBottom: -20,
    overflow: 'hidden',
  }),
);

export const Footer = styled.div(
  mediaQuery({
    borderRadius: [0, '0 0 16px 16px'],
    background: fadeGray,
    display: 'flex',
    padding: '15px 25px',
    borderTop: 'solid 5px white',
  }),
);

export const Strong = styled.strong({
  color: 'black',
  paddingLeft: 10,
  verticalAlign: -2,
});
