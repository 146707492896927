import React, {memo, useEffect, useState} from 'react';

import {useReactiveVar} from '@apollo/client';
import {Box, Text} from 'rebass';
import PropTypes from 'prop-types';
import {useRouteMatch} from 'react-router-dom';

import {blue, fadeBlue, gray, lightRed} from '@renofi/utils/src/colors';
import {
  calculateMonthlyPayment,
  calculatePurchaseLoanAmount,
  usePrevious,
  useRenovationUtils,
} from '@renofi/utils';
import {Sticky, Badge, Toggle} from '@renofi/components';
import {toCurrency} from '@renofi/utils/src/format';

import {SectionSummary, Slider} from '../../components';
import {setCalculatedValues, setLoanAmount} from '../../api/cache';
import {calculatedValuesVar} from '../../api/cache/maxLoan';
import {mortgageBalanceVar} from '../../api/cache/mortgageBalance';
import Terms from '../../Terms';
import {AnimatedHomeWrapper, Strong} from '../styled';
import {getMaxLoanTitle} from '../../utils';
import {AnimatedHome} from '../../Banner';

const Estimation = ({showSummary = true, scenario}) => {
  // Apollo
  const {secondMortgageBalance, firstMortgageBalance} =
    useReactiveVar(mortgageBalanceVar);
  const {maxLoan, propertyPurchasePrice, renovationCost, estimatedDownpayment} =
    useReactiveVar(calculatedValuesVar);
  const prevPropertyPurchasePrice = usePrevious(propertyPurchasePrice);

  const {
    lead,
    loanProduct,
    initialRates,
    initialPeriod,
    isPreferredProduct,
    positionedInterestRate,
    lender,
  } = scenario;

  const {path} = useRouteMatch();
  const isPublic = path.includes('public');
  const isRefi = loanProduct?.productType === 'renofi_cashout_refi';
  const isHeloc = loanProduct?.productType === 'renofi_heloc';
  const {isPurchase: isPurchaseScenario} = useRenovationUtils(lead);
  const isPurchaseProduct = loanProduct?.productType?.includes('purchase');
  const isPurchaseOnlyProduct = loanProduct?.productType === 'purchase_loan';
  const isPurchaseAndRenovationProduct =
    loanProduct?.productType === 'purchase_and_renovate_loan';

  //App
  const initialState = {
    loanAmount: isPurchaseScenario
      ? purchaseLoanAmount()
      : lead.loanAmountDesiredForRenovation,
    selectedTerm: initialPeriod,
    selectedRate:
      isPreferredProduct && positionedInterestRate
        ? positionedInterestRate
        : initialRates && initialRates[0],
  };
  const [state, setState] = useState(initialState);
  const error = state.loanAmount > maxLoan;
  const totalLoanAmount =
    (state.loanAmount || lead.loanAmountDesiredForRenovation || 0) +
    (secondMortgageBalance || 0) +
    (isRefi ? firstMortgageBalance : 0);

  const showTotalLoanAmount = Boolean(secondMortgageBalance) || isRefi;

  useEffect(() => {
    onChange(initialState);
  }, [loanProduct?.name]);

  useEffect(() => {
    onChange();
  }, [secondMortgageBalance, firstMortgageBalance]);

  useEffect(() => {
    if (!prevPropertyPurchasePrice && propertyPurchasePrice) {
      onChange(initialState);
    }
  }, [propertyPurchasePrice]);

  function purchaseLoanAmount() {
    if (isPurchaseProduct) {
      return calculatePurchaseLoanAmount({
        renovationCost: isPurchaseOnlyProduct
          ? 0
          : renovationCost || lead.renovationCost,
        propertyPurchasePrice,
        estimatedDownpayment,
      });
    }
    return lead.renovationCost || lead.loanAmountDesiredForRenovation || 0;
  }

  function onChange(value) {
    const newState = {...state, ...value};
    const monthlyPayment = calculateMonthlyPayment({
      ...newState,
      hasTerms: !loanProduct?.interestOnly,
      loanAmountDesiredForRenovation: totalLoanAmount,
    });
    setState({...newState, monthlyPayment});
    setLoanAmount(newState.loanAmount);
    setCalculatedValues({monthlyPayment});
  }

  function getLabel() {
    const isHomeBuild = lead.loanSubject === 'build_a_home_from_ground_up';
    if (isPurchaseAndRenovationProduct)
      return 'Loan amount for purchase and renovation';
    if (isPurchaseProduct) return 'Loan amount for purchase';
    return `${isHeloc ? 'Cash' : 'Cashout'} amount for ${
      isHomeBuild ? 'build' : 'renovation'
    }`;
  }

  return (
    <div
      className="estimated"
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        height: showSummary ? 'calc(100% - 65px)' : 'calc(100% - 5px)',
      }}>
      <Toggle show={showSummary}>
        <Sticky stickTo=".estimated">
          {(isSticky) => (
            <SectionSummary
              badge={loanProduct?.interestOnly ? 'Interest only' : null}
              isSticky={isSticky}
              title="Estimated minimum monthly payment"
              value={state.monthlyPayment || 0}
              loanProduct={loanProduct}
              lender={lender}
              color={fadeBlue}>
              <Toggle show={showTotalLoanAmount}>
                <Text mt={16} fontSize={14}>
                  Total loan size ({state.selectedTerm} year term)
                </Text>
                <Text>
                  <strong>
                    <Box as="span" fontSize="8px" mr="5px" verticalAlign={4}>
                      $
                    </Box>
                    {toCurrency(totalLoanAmount)}
                  </strong>
                </Text>
                <Text fontSize={14} color={gray}>
                  <Toggle show={Boolean(isRefi && secondMortgageBalance)}>
                    Pay off existing 1st and 2nd{' '}
                  </Toggle>
                  <Toggle show={Boolean(isRefi && !secondMortgageBalance)}>
                    Pay off existing 1st{' '}
                  </Toggle>
                  <Toggle show={!isRefi}>Pay off existing 2nd </Toggle>
                  {`mortgage${
                    isRefi && secondMortgageBalance ? 's' : ''
                  }`} + {isHeloc ? 'Credit line' : 'Cash'} available for
                  renovation
                </Text>
              </Toggle>
            </SectionSummary>
          )}
        </Sticky>
      </Toggle>
      <Slider
        css={{marginTop: showSummary ? 5 : 0}}
        large
        step={5000}
        label={getLabel()}
        tooltipProps={{maxLoan: maxLoan}}
        min={0}
        max={loanProduct.maxLoanAmount}
        error={error}
        points={[maxLoan]}
        onChange={(value) => onChange({loanAmount: value})}
        value={state.loanAmount}>
        <Box textAlign="right" mt="5px">
          <Badge color={error ? lightRed : blue}>
            {getMaxLoanTitle(loanProduct)}
          </Badge>
          <Strong>${maxLoan?.toLocaleString('en-US')}</Strong>
        </Box>

        <AnimatedHomeWrapper display={['none', 'block']}>
          <AnimatedHome
            loanAmount={state.loanAmount}
            maxLoanAmount={loanProduct.maxLoanAmount}
          />
        </AnimatedHomeWrapper>
      </Slider>
      {/*</Toggle>*/}

      {isPublic ? (
        <Slider
          currency={false}
          css={{height: '100%', borderBottomRightRadius: 16}}
          large
          icon="%"
          inputProps={{min: 1, max: 20}}
          label="Interest rate"
          onChange={(value) => onChange({selectedRate: value})}
          value={state.selectedRate}
          noSlider
        />
      ) : (
        <Terms
          scenario={scenario}
          selectedRate={state.selectedRate}
          selectedTerm={state.selectedTerm}
          onChange={onChange}
        />
      )}
    </div>
  );
};

Estimation.propTypes = {
  showSummary: PropTypes.bool,
  scenario: PropTypes.object,
};

export default memo(Estimation);
