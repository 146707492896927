import React from 'react';

import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {Flex} from 'rebass';

import {red} from '@renofi/utils/src/colors';

import {ReactComponent as ArrowIcon} from './back-arrow.svg';

const BackButton = ({to}) => {
  return (
    <Link to={to}>
      <Flex
        css={{
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: 43,
          background: red,
          width: 43,
          height: 43,
          ':hover': {
            filter: ['none', 'brightness(0.95)'],
          },
        }}>
        <ArrowIcon />
      </Flex>
    </Link>
  );
};

BackButton.propTypes = {
  to: PropTypes.string,
};

export default BackButton;
