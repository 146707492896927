import React, {memo} from 'react';

import PropTypes from 'prop-types';
import {Flex, Box} from 'rebass';

import {useScreenSize} from '@renofi/utils';
import {Toggle} from '@renofi/components';

import {Wrapper} from './styled';

const SectionHeading = ({
  step,
  title,
  color,
  titleColor,
  forwardRef,
  children,
  css,
}) => {
  const {isMobile, isDesktop} = useScreenSize();
  return (
    <Wrapper color={color} ref={forwardRef} css={css}>
      <Flex
        fontSize={[15, 18]}
        lineHeight="30px"
        alignItems={['baseline', 'center']}>
        <strong style={{minWidth: 50}}>Step {step}.</strong>
        <Box pl={10} style={{color: titleColor || 'white'}}>
          {title}
        </Box>
        <Toggle show={isDesktop}>
          <>{children}</>
        </Toggle>
      </Flex>
      <Toggle show={isMobile}>
        <>{children}</>
      </Toggle>
    </Wrapper>
  );
};

SectionHeading.propTypes = {
  forwardRef: PropTypes.object,
  step: PropTypes.number,
  css: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  color: PropTypes.string,
  children: PropTypes.node,
  titleColor: PropTypes.string,
};

export default memo(SectionHeading);
