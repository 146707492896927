import React, {memo, useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {Flex, Box} from 'rebass';
import {isEmpty} from 'lodash';

import {sendEvent} from '@renofi/analytics';
import {mediaQuery} from '@renofi/utils';
import {
  lightBlue,
  lightGreen,
  darkBlue,
  lightYellow,
} from '@renofi/utils/src/colors';
import {Toggle} from '@renofi/components';
import {filterTruthyValues} from '@renofi/utils/src/object';

import {SectionHeading, LenderInfo} from '../components';

import EstimationCalculator from './Estimation';
import MaxLoanCalculator from './MaxLoan';
import {Section, Footer} from './styled';
import LoanTypeSelect from './LoanTypeSelect/LoantypeSelect';

function Calculator({scrollToRef, scenario, isPublic}) {
  const {loanProduct, products, lead} = scenario;
  const {inLicensedState} = lead;
  const [dirty, setDirty] = useState(false);
  const [enableMaxLoan, setEnableMaxLoan] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const embedParam = urlParams.get('embed') === 'true';
  const showProductSelect = products.length > 1;

  useEffect(() => {
    sendEvent('Calculator/Calculator-Page');
  }, []);

  useEffect(() => {
    if (dirty) setEnableMaxLoan(true);
  }, [dirty]);

  return (
    <>
      <Toggle show={showProductSelect}>
        <Box bg="white" minWidth="100%" pt={embedParam ? 0 : '5px'}>
          <SectionHeading
            titleColor="#996C1C"
            step={1}
            title="Choose loan type"
            color={lightYellow}
            css={{
              marginBottom: 0,
              padding: ['10px 15px', '8px 15px'],
            }}>
            <LoanTypeSelect scenario={scenario} />
          </SectionHeading>
        </Box>
      </Toggle>
      <Flex
        css={mediaQuery({
          flexDirection: ['column', 'row'],
        })}>
        <Section
          flex={50}
          embed={embedParam}
          css={
            !loanProduct?.id
              ? {filter: 'grayscale(100%)', pointerEvents: 'none', opacity: 0.6}
              : {}
          }>
          <SectionHeading
            titleColor="#0B7517"
            step={showProductSelect ? 2 : 1}
            title="Explore your max borrowing power"
            color={lightGreen}
          />
          <MaxLoanCalculator
            scenario={scenario}
            isPublic={isPublic}
            onChange={(state) => setDirty(!isEmpty(filterTruthyValues(state)))}
          />
        </Section>

        <Section
          embed={embedParam}
          css={
            !enableMaxLoan
              ? {filter: 'grayscale(100%)', pointerEvents: 'none', opacity: 0.6}
              : {}
          }>
          <SectionHeading
            forwardRef={scrollToRef}
            step={showProductSelect ? 3 : 2}
            title="Explore your estimated monthly payments"
            color={lightBlue}
            titleColor={darkBlue}
          />

          <EstimationCalculator scenario={scenario} />
        </Section>
      </Flex>

      <Toggle show={!isPublic}>
        <Footer>
          <LenderInfo
            inLicensedState={inLicensedState}
            link="https://www.renofi.com/renofi-loans/"
            lender={loanProduct?.lender?.name}
            loanProduct={loanProduct?.name}
          />
        </Footer>
      </Toggle>
    </>
  );
}

Calculator.propTypes = {
  scrollToRef: PropTypes.object,
  scenario: PropTypes.object,
  isPublic: PropTypes.bool,
};

export default memo(Calculator);
