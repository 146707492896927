import React from 'react';

import {last} from 'lodash';
import PropTypes from 'prop-types';

import Slider from './Slider';

const StepSlider = ({children, onChange, labels, ...props}) => {
  const points = Array.from(Array(labels.length).keys());
  const min = points[0];
  const max = last(points);

  return (
    <Slider
      noInput
      onChange={(index) => onChange(labels[index])}
      points={points}
      pointLabels={labels}
      min={min}
      max={max}
      {...props}>
      {children}
    </Slider>
  );
};

StepSlider.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  labels: PropTypes.arrayOf(PropTypes.number),
  children: PropTypes.node,
  onChange: PropTypes.func,
};

export default StepSlider;
