import React from 'react';

import {PanelDark} from '@renofi/components';

import {Text} from '../styled';

const LoanAmountAdu = () => {
  return (
    <PanelDark title="Loan amount needed for the ADU">
      <Text mb={10}>The amount you are looking to borrow for your ADU.</Text>
    </PanelDark>
  );
};

export default LoanAmountAdu;
