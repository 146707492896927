import React from 'react';

import {PanelDark} from '@renofi/components';

import {Text} from '../styled';

const ExpectedValueBuild = () => {
  return (
    <PanelDark title="Expected property value upon completion">
      <Text mb={10}>
        The expected property value upon completion of the build contributes to
        the maximum loan amount that you can borrow. What are homes with
        features you’re incorporating in your home build project valued at on{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.redfin.com">
          Redfin
        </a>{' '}
        or{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.zillow.com">
          Zillow
        </a>
        ?
      </Text>

      <Text>
        Your best guess is fine for now - as a part of applying for the loan,
        your home and renovation plans will be appraised.
      </Text>
    </PanelDark>
  );
};

export default ExpectedValueBuild;
