import React, {memo} from 'react';

import PropTypes from 'prop-types';
import {useSpring, animated} from 'react-spring';

import {find, jumpIn, easyIn} from '@renofi/utils/src/animation';

import {ReactComponent as GarageBackground} from './img/garage.svg';
import {ReactComponent as Car} from './img/car.svg';

const AnimatedCar = animated(Car);

const Garage = ({breakpoint, isReturning}) => {
  return (
    <div
      id="garage"
      style={{
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
        height: 234,
      }}>
      <animated.div
        style={{
          position: 'absolute',
          zIndex: 98,
          ...useSpring({
            marginLeft: find([-247, -247, -170, -170, -80, -80], breakpoint),
            config: isReturning ? easyIn : jumpIn,
          }),
        }}>
        <GarageBackground />
        <AnimatedCar
          style={{
            position: 'absolute',
            bottom: 34,
            right: 12,
            ...useSpring({
              from: {bottom: -20},
              to: {bottom: 34},
              delay: 1400,
              config: easyIn,
            }),
            ...useSpring({
              bottom: find([-20, -20, 34], breakpoint),
              delay: isReturning ? 0 : 150,
              config: isReturning ? {duration: 0} : jumpIn,
            }),
          }}
        />
      </animated.div>
    </div>
  );
};

Garage.propTypes = {
  breakpoint: PropTypes.number,
  isReturning: PropTypes.bool,
};

export default memo(Garage);
