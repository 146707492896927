import React, {memo} from 'react';

import {Text} from 'rebass';
import PropTypes from 'prop-types';
import {useRouteMatch} from 'react-router-dom';

import {useScreenSize} from '@renofi/utils';
import {Toggle} from '@renofi/components';

import {Title, Heading, Content} from './styled';

const Banner = ({title}) => {
  // App
  const {isDesktop} = useScreenSize();
  const {path} = useRouteMatch();
  const isPublic = path.includes('public');

  return (
    <>
      <Title>
        <Heading>
          {title ? title : 'Personalized monthly payment calculator'}
        </Heading>
        <Content small={isPublic}>
          <Toggle show={isDesktop}>
            <Text fontSize={18}>
              Use this tool to explore financing options available to fund a
              home renovation project. Simply select the loan type and adjust
              the values to get maximum borrowing power and monthly payment
              estimates.
            </Text>
          </Toggle>
          {/*<AnimationWrapper flex={1}>*/}
          {/*  {isDesktop && loanAmount >= 0 ? (*/}
          {/*    <Animation*/}
          {/*      maxLoanAmount={maxLoanAmount || 500000}*/}
          {/*      loanAmount={loanAmount}*/}
          {/*    />*/}
          {/*  ) : null}*/}
          {/*</AnimationWrapper>*/}
        </Content>
      </Title>
    </>
  );
};

Banner.propTypes = {
  title: PropTypes.string,
};

export default memo(Banner);
