import React from 'react';

import {PanelDark} from '@renofi/components';

import {Text} from '../styled';

const LoanAmountBuild = () => {
  return (
    <PanelDark title="Loan amount needed for the build">
      <Text mb={10}>
        Loan amount needed for the build The amount you’re looking to borrow for
        your build.
      </Text>
    </PanelDark>
  );
};

export default LoanAmountBuild;
