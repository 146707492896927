import styled from '@emotion/styled/macro';
import {Flex} from 'rebass';

import {mediaQuery} from '@renofi/utils';
import {gray} from '@renofi/utils/src/colors';

export const Content = styled(Flex)(
  mediaQuery({
    position: 'relative',
    width: '100%',
    padding: '0 36px 24px 36px',
    color: gray,
    textAlign: 'center',
  }),
);

export const Title = styled.div(
  mediaQuery({
    padding: [15, '36px 40px 100px 40px'],
    paddingBottom: [0, 0],
    borderRadius: ['none', '16px 16px 0 0'],
    overflow: 'hidden',
    background: [
      'white',
      'linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(229,245,255,1) 470px)',
    ],
  }),
);

export const Heading = styled.h1(
  mediaQuery({
    textAlign: 'center',
    lineHeight: ['22px', '38px'],
    fontSize: [22, 36],
  }),
  ({small}) => ({
    marginBottom: small ? 16 : 24,
  }),
);
