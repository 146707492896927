import styled from '@emotion/styled/macro';

import {mediaQuery} from '@renofi/utils';
import {SelectField} from '@renofi/components';
import {darkGray, lightGray} from '@renofi/utils/src/colors';

export const StyledSelectField = styled(SelectField)(
  mediaQuery({
    fontSize: [16, 24],
    width: ['100%', '110%'],
    height: 44,
    padding: '7px 13px',
    color: darkGray,
    fontWeight: 800,
    paddingRight: [40, 80],
    border: `solid 1px ${lightGray}`,
    borderRadius: 4,
    ':focus': {
      outline: 'none',
    },
  }),
);
