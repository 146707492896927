export default ({
  homeValue,
  afterRenovatedValue = 0,
  firstMortgageBalance = 0,
  secondMortgageBalance = 0,
  maxLoanAmount = 500000,
  maxPeakCltv = 1.25,
  maxArvCltv = 0.95,
}) => {
  if (!homeValue) return 0;

  const ninetyFivePercent =
    afterRenovatedValue * maxArvCltv - firstMortgageBalance;
  const oneTwentyFivePercent =
    Math.max(firstMortgageBalance / homeValue, maxPeakCltv) * homeValue -
    firstMortgageBalance;
  const amount =
    Math.min(ninetyFivePercent, oneTwentyFivePercent) - secondMortgageBalance;

  if (amount <= 0) return 0;
  if (amount >= maxLoanAmount) return maxLoanAmount;
  return amount;
};
