import React from 'react';

import {PanelDark} from '@renofi/components';

import {Text} from '../styled';

const ExpectedValueAdu = () => {
  return (
    <PanelDark title="Expected home value post ADU completion">
      <Text mb={10}>
        The expected value of your home after the ADU project is completed
        contributes to the maximum loan amount that you can borrow.
      </Text>
    </PanelDark>
  );
};

export default ExpectedValueAdu;
