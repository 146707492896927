import React from 'react';

import {noop, first, last} from 'lodash';
import PropTypes from 'prop-types';
import {Flex, Text, Link} from 'rebass';

import {StepSlider} from '../components';

const RatesSlider = ({rates = [], onChange = noop, ...props}) => {
  const sortedRates = [...rates].sort((a, b) => a - b);

  const onClick = (value) => {
    onChange({selectedRate: value});
  };

  return (
    <StepSlider
      {...props}
      label="Interest rate"
      badge="Example rate"
      suffix="% APR"
      large
      noSlider={sortedRates.length === 1}
      onChange={onClick}
      labels={sortedRates}>
      {sortedRates.length > 1 && (
        <Flex mt="5px" css={{cursor: 'pointer'}} justifyContent="space-between">
          <Link onClick={() => onClick(first(sortedRates))}>Low rate</Link>
          {sortedRates.length === 3 ? (
            <Link onClick={() => onClick(sortedRates[1])}>Mid rate</Link>
          ) : null}
          <Link onClick={() => onClick(last(sortedRates))}>High rate</Link>
        </Flex>
      )}
      <Text mt={15} fontSize={14} lineHeight="20px">
        Actual rate is calculated when you apply with the lender.
      </Text>
    </StepSlider>
  );
};

RatesSlider.propTypes = {
  children: PropTypes.node,
  rates: PropTypes.array,
  onChange: PropTypes.func,
};

export default RatesSlider;
