import styled from '@emotion/styled/macro';

import {darkBlue} from '@renofi/utils/src/colors';

export const Wrapper = styled.div({
  color: 'white',
  background: darkBlue,
  fontSize: 14,
  lineHeight: '17px',
  textAlign: 'center',
  padding: 20,
  borderRadius: 16,
  boxShadow: '0 6px 10px rgba(0,0,0,0.48)',
});
