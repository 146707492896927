import styled from '@emotion/styled/macro';
import {Flex} from 'rebass';

import {mediaQuery} from '@renofi/utils';
import {Panel} from '@renofi/components';

export const GreenWrapper = styled(Flex)(
  mediaQuery({
    flexDirection: 'column',
    textAlign: 'left',
    padding: ['35px 15px', '35px 25px'],
    margin: '0 -15px -15px -15px',
    borderRadius: '0 0px 16px 16px',
    position: 'relative',
    zIndex: 1,
    overflow: 'hidden',
    ':after': {
      position: 'absolute',
      zIndex: -1,
      content: '" "',
      width: '900vw',
      height: '900vw',
      left: '50%',
      top: 0,
      transform: 'translateX(-50%)',
      borderRadius: 10000000,
      background: `linear-gradient(180deg, #E3F9E5 0%, #C2F0C7 100%)`,
    },
  }),
);

export const GradientPanel = styled(Panel)(
  mediaQuery({
    marginTop: [0, 25],
    background:
      'linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(229,245,255,1) 100%)',
  }),
);
