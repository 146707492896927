import styled from '@emotion/styled/macro';
import {Link as RouterLink} from 'react-router-dom';
import {Box} from 'rebass';

import {mediaQuery} from '@renofi/utils';

import {ReactComponent as ArrowIcon} from './back-arrow.svg';

export const Arrow = styled(ArrowIcon)({
  transform: 'scaleX(-1)',
});

export const Link = styled(RouterLink)({
  fontSize: 16,
  marginTop: 20,
});

export const ZipWrapper = styled(Box)(
  mediaQuery({
    maxWidth: 379,
    margin: '30px auto 20px auto',
  }),
);
