import React, {memo} from 'react';

import {Image, Flex, Box, Text} from 'rebass';

import {FooterLinks, renofiLogoDarkIconSrc} from '@renofi/components';
import {mediaQuery} from '@renofi/utils';

import email from './img/email-icon.svg';
import mobile from './img/mobile-icon.svg';
import errorImg from './img/error.png';
import {
  Link,
  Logo,
  Container,
  Content,
  MessageBox,
  Title,
  BorderBox,
  BorderBoxTitle,
  BorderBoxStrong,
} from './styled';

function Error() {
  return (
    <div>
      <Container>
        <Logo src={renofiLogoDarkIconSrc} alt="RenoFi" />
        <Content>
          <Image src={errorImg} />
          <Title mb={20}>
            We’re trying to find your monthly payment calculator
          </Title>
          <MessageBox>
            The RenoFi monthly payment calculator is personalized for each and
            every home owner. Use the personalized link in your next steps email
            to access your monthly payment calculator.
          </MessageBox>

          <Box
            justifyContent="space-between"
            css={mediaQuery({display: ['block', 'flex']})}>
            <BorderBox>
              <BorderBoxTitle>
                Received an email but can’t find it?{' '}
              </BorderBoxTitle>
              <BorderBoxStrong>Calculator link isn’t working. </BorderBoxStrong>
              <Text mb={10}>Please reach out via the channels below:</Text>

              <Flex mb={10}>
                <Image css={{width: 16}} mr={10} src={email} />
                <Text>
                  Email us at{' '}
                  <Link fontSize={16} href="mailto:sayhi@renofi.com">
                    sayhi@renofi.com
                  </Link>
                </Text>
              </Flex>

              <Flex>
                <Image
                  css={{width: 14}}
                  pl="2px"
                  pr="2px"
                  mr={10}
                  src={mobile}
                />
                <Text>Call us on 855-736-6341</Text>
              </Flex>
            </BorderBox>

            <BorderBox>
              <BorderBoxTitle>Haven’t received an email? </BorderBoxTitle>
              <Text mb={20}>
                Complete our{' '}
                <Link
                  target="_blank"
                  href="https://qualify.renofi.com"
                  fontSize={16}>
                  Get Estimate
                </Link>{' '}
                form to receive your personalized monthly payment calculator.
              </Text>
              <Text mb={10}>
                For details on our renovation loans, visit{' '}
                <Link target="_blank" href="https://renofi.com" fontSize={16}>
                  Renofi.com
                </Link>
                .
              </Text>
            </BorderBox>
          </Box>
        </Content>

        <Box px={10} pb={30}>
          <FooterLinks center />
        </Box>
      </Container>
    </div>
  );
}

export default memo(Error);
