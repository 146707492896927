import {useNewHomeownerCache, useScenario} from '../api';

export default function useLabels(lead = {}) {
  const {scenario} = useScenario();
  const {newHomeowner} = useNewHomeownerCache();
  const loanSubject =
    lead?.loanSubject ||
    scenario.lead?.loanSubject ||
    newHomeowner?.loanSubject ||
    'renovate_current_home';

  const labels = {
    renovate_current_home: {
      homeValue: 'Current home value',
      afterRenovatedValue: 'Expected home value post renovation',
      loanAmountDesiredForRenovation: 'Loan amount needed for the renovation',
      firstMortgageBalance:
        'Total outstanding mortgage balance on the property',
    },
    renovate_home_being_purchased: {
      propertyPurchasePrice: 'Estimated purchase price',
      afterRenovatedValue: 'Expected home value post renovation',
      renovationCost: 'Loan amount needed for the renovation',
      estimatedDownpayment: 'Estimated downpayment',
    },
    build_a_home_from_ground_up: {
      homeValue: 'Estimated current property value',
      afterRenovatedValue: 'Expected property value upon completion',
      loanAmountDesiredForRenovation: 'Loan amount needed for the build',
      firstMortgageBalance: 'Estimated mortgage balance on the property',
    },
    renovate_or_build_adu: {
      homeValue: 'Current home value where the ADU will be located',
      afterRenovatedValue: 'Expected home value post ADU completion',
      loanAmountDesiredForRenovation: 'Loan amount needed for the ADU',
      firstMortgageBalance:
        'Total outstanding mortgage balance on the property',
    },
  };

  return labels[loanSubject];
}
