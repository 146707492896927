import React from 'react';

import {last} from 'lodash';

import {PanelDark} from '@renofi/components';

import {useScenario} from '../../api';
import {Text} from '../styled';

const LoanDetails = () => {
  const {
    scenario: {loanProduct},
  } = useScenario();

  if (!loanProduct) return null;

  const {variants} = loanProduct;
  if (variants?.length > 0) {
    const repaymentPeriods = variants.map((variant) => variant.repaymentPeriod);
    const firstTerms = repaymentPeriods.slice(0, -1).join(', ');
    const lastTerm = last(repaymentPeriods);
    const termsText =
      repaymentPeriods.length > 1
        ? `${firstTerms} and ${lastTerm}`
        : `${lastTerm}`;
    return (
      <PanelDark title="Loan details">
        <Text mb={10}>
          We have matched you with the {loanProduct.name} based on the details
          you have provided.
        </Text>
        <Text>
          This loan has term options of {termsText} years. Contact your Loan
          Advisor for more details.
        </Text>
      </PanelDark>
    );
  } else {
    return (
      <PanelDark title="Loan details">
        <Text mb={10}>
          We have matched you with the {loanProduct.name} loan based on the
          details you have provided.
        </Text>
        <Text>
          This loan has a 10 year interest only draw period followed by a{' '}
          {loanProduct.repaymentPeriod}
          year amortization period. Contact your Loan Advisor for more details.
        </Text>
      </PanelDark>
    );
  }
};

export default LoanDetails;
