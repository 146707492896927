import React, {useEffect} from 'react';

import PropTypes from 'prop-types';
import {Flex} from 'rebass';

import {Modal} from '@renofi/components';
import {initHotCall} from '@renofi/analytics/src/chilipiper';
import {sendEvent} from '@renofi/analytics';

import ChilipiperModule from '../Chilipiper';

import {modalCustomStyles} from './styled';

const HotCallModal = ({lead, light, show, onClose, analyticsPrefix}) => {
  useEffect(() => {
    if (show) {
      sendEvent(`${analyticsPrefix}/HotCall-Modal-Open`);
    }
  }, [show]);

  if (!show) return null;

  function onReady(newLead) {
    initHotCall(newLead, analyticsPrefix);
  }

  function onModalClose() {
    sendEvent(`${analyticsPrefix}/HotCall-Modal-Close`);
    onClose();
  }

  return (
    <Modal
      light={light}
      onClose={onModalClose}
      show={show}
      css={modalCustomStyles}
      width={1030}>
      <Flex minHeight={543} width="100%">
        <ChilipiperModule
          lead={lead}
          onReady={onReady}
          buttonTitle="Speak now with advisor"
          formTitle="Before speaking with advisor please provide some basic details"
          title=" "
        />
      </Flex>
    </Modal>
  );
};

HotCallModal.propTypes = {
  lead: PropTypes.object,
  light: PropTypes.bool,
  show: PropTypes.bool,
  analyticsPrefix: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default HotCallModal;
