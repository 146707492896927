import React from 'react';

import PropTypes from 'prop-types';
import {has} from 'lodash';

import {
  constants,
  useUTMTags,
  useRaasSourceLender,
  getRaasPartnerName,
} from '@renofi/utils';
import {renofiLogoDarkIconSrc} from '@renofi/components';
import {RAAS_PARTNER_LOGOS} from '@renofi/modules';
import {RAAS_PARTNERS_CUSTOM_WIDTH} from '@renofi/utils/src/const';

import {Logo, SimpleHeaderWrapper} from './styled';

const SimpleHeader = ({css = {}}) => {
  const {utmTags} = useUTMTags(constants.UTM_COOKIE_KEYS.renofi);
  const raasSourceLender = useRaasSourceLender(utmTags);

  return (
    <SimpleHeaderWrapper css={css}>
      <Logo src={renofiLogoDarkIconSrc} alt="RenoFi" />
      {has(RAAS_PARTNER_LOGOS, raasSourceLender) && (
        <Logo
          width={RAAS_PARTNERS_CUSTOM_WIDTH[raasSourceLender]}
          data-testid="partner-logo"
          src={RAAS_PARTNER_LOGOS[raasSourceLender]}
          alt={getRaasPartnerName(raasSourceLender)}
        />
      )}
    </SimpleHeaderWrapper>
  );
};

SimpleHeader.propTypes = {
  css: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default SimpleHeader;
