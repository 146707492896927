import styled from '@emotion/styled/macro';
import {Text} from 'rebass';

import {darkBlue} from '@renofi/utils/src/colors';
import {mediaQuery} from '@renofi/utils';

export const Wrapper = styled.div(
  mediaQuery({
    position: 'relative',
    padding: ['0 15px 15px 15px', '0 25px 15px 25px'],
  }),
  ({isSticky, color, css}) => ({
    background: color,
    transition: 'box-shadow .5s',
    boxShadow: isSticky ? '1px 0px 6px 0px #0000006b' : 'none',
    ...css,
  }),
);

export const TooltipWrapper = styled.div({}, ({isSticky}) =>
  mediaQuery({
    padding: ['25px 15px 0px 0px', '20px 25px 2px 10px'],
    visibility: isSticky ? 'hidden' : 'visible',
    opacity: isSticky ? '0' : '1',
    transition: 'visibility 0.2s, opacity 0.2s linear',
  }),
);

export const Title = styled(Text)(
  mediaQuery(
    {
      color: darkBlue,
      lineHeight: ['20px', '24px'],
      fontSize: [18, 24],
    },
    ({disabled}) => ({
      paddingRight: disabled ? 0 : 40,
    }),
  ),
);

export const Value = styled(Text)(
  mediaQuery({
    marginTop: [5, 0],
    fontWeight: 'bold',
    lineHeight: ['40px', '60px'],
    fontSize: [52, 66],
    display: 'flex',
    alignItems: 'start',
    paddingTop: 20,
    paddingLeft: 20,
    position: 'relative',
    ':before': {
      fontSize: [20, 24],
      content: '"$"',
      position: 'absolute',
      left: 0,
      top: [8, 5],
    },
  }),
  ({color}) => ({
    color,
  }),
);
