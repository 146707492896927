import {useQuery} from '@apollo/client';

import {TOP_LOAN_PRODUCTS} from '../queries/topLoanProducts';

export default function useTopLoanProducts(options) {
  const {refetch} = useQuery(TOP_LOAN_PRODUCTS, {...options, skip: true});
  const imperativelyCallQuery = (variables) => {
    return refetch(variables);
  };
  return imperativelyCallQuery;
}
