import React, {memo, useState, useEffect} from 'react';

import {useHistory} from 'react-router-dom';
import {Text, Box} from 'rebass';

import {calculateMaxLoan, useScreenSize} from '@renofi/utils';
import {lightGreen, gray, darkGray} from '@renofi/utils/src/colors';
import {Button, StickyBottom, SelectField, Label} from '@renofi/components';
import {sendEvent} from '@renofi/analytics';
import {forEachLoanSubject} from '@renofi/api/src/enums';
import useRenovationUtils from '@renofi/utils/src/hooks/useRenovationUtils';

import {setNewHomeowner} from '../../api/cache';
import {useNewHomeownerCache} from '../../api';
import {SectionHeading, SliderWrapper} from '../../components';
import EnterValue from '../../Tooltips/Help/EnterValue';
import useLabels from '../../utils/useLabels';

import {Footer, customSliderStyles} from './styled';
import FormInputs, {PurchaseFormInputs} from './FormInputs';

const Financials = () => {
  // Apollo
  const {newHomeowner = {}} = useNewHomeownerCache();
  // App
  const defaultLead = {
    loanSubject: newHomeowner.loanSubject || null,
    homeValue: newHomeowner.homeValue || null,
    loanAmountDesiredForRenovation:
      newHomeowner.loanAmountDesiredForRenovation || null,
    renovationCost: newHomeowner.renovationCost || null,
    afterRenovatedValue: newHomeowner.afterRenovatedValue || null,
    firstMortgageBalance: newHomeowner.firstMortgageBalance || null,
    propertyPurchasePrice: newHomeowner.propertyPurchasePrice || null,
    estimatedDownpayment: newHomeowner.estimatedDownpayment || null,
  };
  const {isMobile, isDesktop} = useScreenSize();
  const history = useHistory();
  const [selected, setSelected] = useState(1);
  const [dirty, setDirty] = useState(false);
  const [lead, setLead] = useState(defaultLead);
  const {isPurchase} = useRenovationUtils(lead);
  const labels = useLabels(lead);
  const [maxLoan, setMaxLoan] = useState(calculateMaxLoan(lead));

  useEffect(() => {
    setSelected(1);
    sendEvent('Calculator/New-Homeowner/Financials-Page');
  }, []);

  if (!newHomeowner.zipCode) {
    history.push('/homeowner/zip');
  }

  function onChange(value) {
    const newLead = value.loanSubject ? value : {...lead, ...value};
    setLead(newLead);
    setMaxLoan({
      maxLoan: calculateMaxLoan(newLead),
      isMaxLoanFromDti: false,
    });
  }

  function onSubmit() {
    const leadWithCalculatedValues = isPurchase ? calculateValues(lead) : lead;
    setNewHomeowner(leadWithCalculatedValues);
    setTimeout(() =>
      history.push(isMobile ? '/renovation/preview/2' : '/renovation/preview'),
    );
  }

  function calculateValues() {
    const {propertyPurchasePrice, renovationCost, estimatedDownpayment} = lead;
    if (
      isPurchase &&
      renovationCost &&
      propertyPurchasePrice &&
      estimatedDownpayment
    ) {
      return {
        ...lead,
        loanAmountDesiredForRenovation: renovationCost,
      };
    }
  }

  function isMinLoanInvalid() {
    return (
      dirty &&
      (lead.loanAmountDesiredForRenovation || lead.renovationCost) < 1000
    );
  }

  function isFormCompleted() {
    return Object.keys(labels).every(
      (key) => lead.hasOwnProperty(key) && !isEmpty(lead[key]),
    );
  }

  function isEmpty(value) {
    return value === '' || value === null || value === undefined;
  }

  function isFormInvalid() {
    return !isFormCompleted() || maxLoan <= 0 || isMinLoanInvalid();
  }

  return (
    <>
      <Text fontSize={25} color={darkGray} mb={35} mt={20}>
        Personalized monthly payment calculator
      </Text>

      <Box
        css={{
          textAlign: 'left',
          position: 'relative',
          borderRadius: '0 0 18px 18px',
        }}
        mx={-15}
        bg="white">
        <SectionHeading
          step={1}
          title="Tell us about your project"
          titleColor="#0B7517"
          color={lightGreen}
        />
        <div className="max-loan" style={{position: 'relative'}}>
          <SliderWrapper
            selected={selected === 1}
            large
            css={customSliderStyles}>
            <Label mb="6px" fontSize={16}>
              I’m renovating
            </Label>
            <SelectField
              xLarge
              fontSize={18}
              autoComplete="false"
              value={lead.loanSubject || ''}
              onChange={(value) => onChange({loanSubject: value})}
              onFocus={() => setSelected(1)}
              id="loanSubject"
              name="loanSubject">
              {lead.loanSubject ? null : <option />}
              {forEachLoanSubject((key, value) => (
                <option key={key} value={key}>
                  {value.replace('Renovate', '')}
                </option>
              ))}
            </SelectField>
          </SliderWrapper>

          {!isPurchase && (
            <FormInputs
              lead={lead}
              onChange={onChange}
              setSelected={setSelected}
              selected={selected}
              setDirty={setDirty}
              isMinLoanInvalid={isMinLoanInvalid}
            />
          )}

          {isPurchase && (
            <PurchaseFormInputs
              lead={lead}
              onChange={onChange}
              setSelected={setSelected}
              selected={selected}
              setDirty={setDirty}
              isMinLoanInvalid={isMinLoanInvalid}
            />
          )}
        </div>

        <EnterValue show={selected === 1 && !lead.homeValue} />

        {isDesktop && (
          <Footer>
            <Text color={gray}>All details required</Text>
            <Button small disabled={isFormInvalid()} mt={20} onClick={onSubmit}>
              Continue to payments & rates
            </Button>
          </Footer>
        )}
      </Box>

      {isMobile && (
        <StickyBottom boxProps={{width: '100vw', ml: -15}}>
          <Button tabIndex={5} disabled={isFormInvalid()} onClick={onSubmit}>
            Continue to payments & rates
          </Button>
        </StickyBottom>
      )}
    </>
  );
};

export default memo(Financials);
