import {makeVar} from '@apollo/client';

// Reactive variables
export const mortgageBalanceVar = makeVar({
  firstMortgageBalance: '',
  secondMortgageBalance: '',
});

// Setter
export const setMortgageBalance = (value) => {
  mortgageBalanceVar({...mortgageBalanceVar(), ...value});
};

// Apollo field policy
export const mortgageBalancePolicy = {
  read() {
    return mortgageBalanceVar();
  },
};
