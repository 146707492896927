import styled from '@emotion/styled/macro';
import {Button} from 'rebass';

import {lightGreen, darkBlue} from '@renofi/utils/src/colors';

export const StyledButton = styled(Button)({
  color: darkBlue,
  borderRadius: 25,
  fontSize: 14,
  fontWeight: 'bold',
  background: lightGreen,
  padding: '8px 25px',
  cursor: 'pointer',
  ':focus': {
    outline: 'none',
  },
  ':hover': {
    filter: 'brightness(0.92)',
  },
});
