import React from 'react';

import {createRoot} from 'react-dom/client';
import {ApolloClient, ApolloProvider, from, HttpLink} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {RetryLink} from '@apollo/client/link/retry';
import {BrowserRouter as Router} from 'react-router-dom';
import {onError} from '@apollo/client/link/error';

import initPolyfills from '@renofi/utils/src/polyfills';
import {FlagProvider} from '@renofi/utils/src/feature-flags';
import {fetchWithRateLimitHandler, retryLinkConfig} from '@renofi/api';
import {logGraphQLError} from '@renofi/analytics';

import {initAnalytics} from './analytics';
import {cache} from './api/cache';
import App from './App';

import './index.css';
import '@renofi/analytics/src/chilipiper.css';

initPolyfills();
initAnalytics();

const apiUrl = process.env.REACT_APP_GRAPHQL_PROXY_URL;
const httpLink = new HttpLink({
  uri: apiUrl,
  fetch: fetchWithRateLimitHandler,
});
const retryLink = new RetryLink(retryLinkConfig);
// Log any GraphQL errors or network error that occurred
const errorLink = onError(logGraphQLError);

const authLink = setContext((_, {headers}) => {
  return {headers};
});

const client = new ApolloClient({
  cache,
  name: process.env.REACT_APP_SERVICE_NAME,
  version: process.env.REACT_APP_COMMIT_REF,
  link: from([errorLink, authLink, retryLink, httpLink]),
});

const unleashConfig = {
  url: process.env.REACT_APP_UNLEASH_PROXY_URL,
  clientKey: process.env.REACT_APP_UNLEASH_CLIENT_KEY,
  refreshInterval: 15,
  appName: `calculator-app-${process.env.REACT_APP_ENV}`,
  environment: process.env.REACT_APP_ENV,
};
const rootNode = document.getElementById('root');
const root = createRoot(rootNode);

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <FlagProvider config={unleashConfig}>
        <Router>
          <App />
        </Router>
      </FlagProvider>
    </ApolloProvider>
  </React.StrictMode>,
);
