import React, {useState} from 'react';

import PropTypes from 'prop-types';
import {Box, Flex} from 'rebass';

import {sendEvent, chilipiper} from '@renofi/analytics';
import {getEnvironmentUrl} from '@renofi/utils';
import {gray} from '@renofi/utils/src/colors';
import HotCallModal from '@renofi/modules/src/HotCallModal';
import {
  leeImage,
  Button,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Toggle,
} from '@renofi/components';

import {useScenario} from '../api';

import Links from './Links';
import {ReactComponent as CalendarIcon} from './img/calendar.svg';
import {
  ButtonWrapper,
  Quote,
  Subtitle,
  Thumbnail,
  Title,
  WhatNextWrapper,
} from './styled';

const WhatNext = ({title, subtitle}) => {
  // Apollo
  const {scenario} = useScenario();
  const {lead = {}} = scenario;
  const {stars, inLicensedState, loanAmountDesiredForRenovation} = lead;
  const [showCallMeNowModal, setShowCallMeNowModal] = useState(false);
  const isQualified =
    stars !== 1 &&
    (inLicensedState || loanAmountDesiredForRenovation <= 150000);

  function onPrequalClick() {
    const path = `/loan-options/${lead.id}`;
    window.open(`${getEnvironmentUrl('qualify', '4010')}${path}`, '_blank');
  }

  function onScheduleClick() {
    if (lead.prequalCompletedAt) {
      return chilipiper.initSchedule({
        lead,
        analyticsPrefix: 'Calculator',
      });
    }
    const path = `/loan-options/${lead.id}?schedule=true`;
    window.open(`${getEnvironmentUrl('qualify', '4010')}${path}`, '_blank');
  }

  return (
    <Box ml={[0, -18]} pb={20}>
      <WhatNextWrapper showQuote>
        <Quote>
          <Thumbnail src={leeImage} />
          {title ? <Title>{title}</Title> : null}
          <Subtitle color={gray}>{subtitle}</Subtitle>
        </Quote>

        <Tabs>
          <TabList>
            <Tab>Next steps</Tab>
            <Tab>Learn more</Tab>
          </TabList>

          <TabPanel>
            <Flex
              flexDirection={['column', 'row']}
              alignItems="center"
              flexWrap="wrap">
              <Toggle show={isQualified}>
                <ButtonWrapper width={320}>
                  <Button
                    noCaps
                    onClick={() => {
                      onScheduleClick();
                      sendEvent('Calculator/Schedule-Button-Click');
                    }}>
                    <Flex justifyContent="center">
                      <CalendarIcon
                        style={{marginLeft: -15, marginRight: 10}}
                      />
                      Schedule a call with advisor
                    </Flex>
                  </Button>
                </ButtonWrapper>
              </Toggle>

              <Toggle show={!isQualified}>
                <ButtonWrapper width={220}>
                  <Button
                    onClick={() => {
                      onPrequalClick();
                      sendEvent('Calculator/Prequal-Button-Click');
                    }}>
                    Check Eligibility
                  </Button>
                </ButtonWrapper>
              </Toggle>
            </Flex>
          </TabPanel>

          <TabPanel>
            <Subtitle>What would you like to know?</Subtitle>

            <Links analyticsPrefix="Calculator" />
            <br />
          </TabPanel>
        </Tabs>

        <HotCallModal
          lead={lead}
          onClose={() => setShowCallMeNowModal(false)}
          show={showCallMeNowModal}
          analyticsPrefix="Calculator"
        />
      </WhatNextWrapper>
    </Box>
  );
};

WhatNext.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default WhatNext;
