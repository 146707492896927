import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {Text, Box} from 'rebass';

import {useRequestCalculatorLink} from '@renofi/api';
import {sendEvent} from '@renofi/analytics';
import {blue, lightBlue, darkBlue} from '@renofi/utils/src/colors';
import {Button, Alert} from '@renofi/components';

import HelpBox from '../HelpBox';

import {ReactComponent as InfoIcon} from './info-icon.svg';
import {ReactComponent as CloseIcon} from './close.svg';
import {Wrapper, CloseButton} from './styled';

const ResendLink = ({email, onClose}) => {
  const [showError, setShowError] = useState(false);
  const {requestCalculatorLink, loading, response} = useRequestCalculatorLink();

  useEffect(() => {
    sendEvent('Calculator/New-Homeowner/Resend-Email-Popup');
  }, []);

  async function onClick() {
    if (loading || response?.success) return;
    const {data} = await requestCalculatorLink({variables: {email}});
    setShowError(!data?.requestCalculatorLink?.success);

    if (data?.requestCalculatorLink?.success) {
      sendEvent('Calculator/New-Homeowner/Resend-Email-Success');
    }
  }

  return (
    <Wrapper>
      <HelpBox>
        <CloseButton
          bgColor={darkBlue}
          borderColor={darkBlue}
          onClick={onClose}>
          <CloseIcon />
        </CloseButton>
        <Box>
          <InfoIcon />

          {response?.success ? (
            <Text my={20} fontSize={14}>
              We just sent your login link. Please use it to view your
              personalized calculator.
            </Text>
          ) : (
            <>
              <Text my={20} fontSize={14}>
                It looks like you’ve previously used the personalized monthly
                payment calculator.
              </Text>

              <Text my={20} fontSize={14}>
                Click the button below and we’ll resend your <br /> login link.
              </Text>
            </>
          )}

          {showError ? (
            <Alert>
              We didn’t find a match for {email}. Check your email address and
              try again.
            </Alert>
          ) : null}

          <Button
            success={response?.success}
            loading={loading}
            color={lightBlue}
            bgColor={darkBlue}
            disabledBgColor={darkBlue}
            borderColor={blue}
            small
            disabled={loading || response?.success}
            onClick={onClick}>
            {loading
              ? 'Sending'
              : response?.success
                ? 'Check your mail'
                : 'Resend login link'}
          </Button>
        </Box>
      </HelpBox>
    </Wrapper>
  );
};

ResendLink.propTypes = {
  email: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ResendLink;
