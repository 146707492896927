import React, {memo} from 'react';

import PropTypes from 'prop-types';
import {Flex, Text, Link} from 'rebass';

import {darkBlue} from '@renofi/utils/src/colors';
import {Toggle} from '@renofi/components';

import {Tooltip} from '../../Tooltips';

import {Strong} from './styled';

const LenderInfo = ({
  lender,
  loanProduct,
  link,
  noTooltip,
  inLicensedState,
}) => {
  return (
    <Flex data-testid="lender-details">
      {noTooltip || (
        <Tooltip
          id="Loan details"
          css={{
            marginRight: 10,
            position: 'inherit',
          }}
        />
      )}
      <Text color="#212F49" fontSize={15} textAlign="left">
        <Strong>Loan type:</Strong>{' '}
        {link ? (
          <Link
            color={darkBlue}
            href={link}
            target="_blank"
            rel="noopener noreferrer">
            {loanProduct}
          </Link>
        ) : (
          <span>{loanProduct}</span>
        )}
        {'. '}
        <Toggle show={!inLicensedState}>
          <Strong>Example lender:</Strong> {lender}
        </Toggle>
      </Text>
    </Flex>
  );
};

LenderInfo.propTypes = {
  lender: PropTypes.string,
  link: PropTypes.string,
  loanProduct: PropTypes.string,
  noTooltip: PropTypes.bool,
  inLicensedState: PropTypes.bool,
};

export default memo(LenderInfo);
