export default {
  renofiHeloc: {
    cashDisbursementType: 'full_upfront',
    id: '1401',
    interestOnly: true,
    isFirstLien: false,
    lender: {
      name: 'USALLIANCE Financial',
      code: 'us_alliance',
      id: 'us_alliance',
      __typename: 'AcquisitionLender',
    },
    lenderId: 'us_alliance',
    maxArvCltv: 0.9,
    maxLoanAmount: 500000,
    maxPeakCltv: 1.25,
    minCreditScore: 660,
    minLoanAmount: 25000,
    name: 'RenoFi HELOC',
    productType: 'renofi_heloc',
    rateType: 'variable',
    rates: [8.5, 10, 11.125],
    refinanceRequired: false,
    repaymentPenalty: false,
    repaymentPeriod: 20,
    maxDti: 0.45,
    variants: [
      {
        id: '1401',
        rates: [8.5, 10, 11.125],
        repaymentPeriod: 20,
        __typename: 'LoanProductV2',
      },
    ],
    __typename: 'LoanProductV2',
  },
  renofiHomeEquity: {
    cashDisbursementType: 'full_upfront',
    id: '1402',
    interestOnly: false,
    isFirstLien: false,
    lender: {
      name: 'USALLIANCE Financial',
      code: 'us_alliance',
      id: 'us_alliance',
      __typename: 'AcquisitionLender',
    },
    lenderId: 'us_alliance',
    maxArvCltv: 0.9,
    maxLoanAmount: 500000,
    maxPeakCltv: 1.25,
    minCreditScore: 720,
    minLoanAmount: 25000,
    name: 'RenoFi Fixed Rate Home Equity',
    productType: 'renofi_home_equity',
    rateType: 'fixed',
    rates: [8.625, 8.625, 9.125],
    refinanceRequired: false,
    repaymentPenalty: false,
    repaymentPeriod: 20,
    maxDti: 0.45,
    variants: [
      {
        id: '1402',
        rates: [8.625, 8.625, 9.125],
        repaymentPeriod: 20,
        __typename: 'LoanProductV2',
      },
      {
        id: '1402',
        rates: [8.625, 8.625, 9.125],
        repaymentPeriod: 15,
        __typename: 'LoanProductV2',
      },
      {
        id: '1402',
        rates: [7.5, 7.5, 8.125],
        repaymentPeriod: 10,
        __typename: 'LoanProductV2',
      },
      {
        id: '1402',
        rates: [6.75, 6.75, 7.375],
        repaymentPeriod: 5,
        __typename: 'LoanProductV2',
      },
    ],
    __typename: 'LoanProductV2',
  },
};
