import React from 'react';

import {PanelDark, Toggle} from '@renofi/components';

import {Text, Strong, Link} from '../styled';

const ExpectedValue = ({isPublic}) => {
  return (
    <PanelDark title="Expected home value post renovation">
      <Text mb={10}>
        The expected value of your home after the renovation is completed
        contributes to the maximum loan amount that you can borrow.
      </Text>
      <Toggle show={!isPublic}>
        <Text mb={10}>
          What are homes with the features you&apos;re incorporating into your
          renovated home valued at on{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.redfin.com">
            Redfin
          </a>{' '}
          or{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.zillow.com">
            Zillow
          </a>
          ? Your best guess is fine for now - as a part of applying for the
          loan, your home and renovation plans will be appraised.
        </Text>
        <Strong>Related reading from our blog</Strong>
        <Link
          target="_blank"
          href="https://www.renofi.com/blog/how-appraisals-work-for-renovation-loans/">
          How do appraisals work for renovation loans?
        </Link>
      </Toggle>
    </PanelDark>
  );
};

export default ExpectedValue;
