import styled from '@emotion/styled/macro';
import {Box, Text} from 'rebass';

import {mediaQuery} from '@renofi/utils';

export const PublicFooter = styled(Box)(() =>
  mediaQuery({
    paddingTop: 60,
    paddingBottom: 60,
    paddingLeft: [16, 0],
    paddingRight: [16, 0],
    width: '100%',
    backgroundColor: '#ECF7FB',
  }),
);

export const PublicContainer = styled(Box)(() =>
  mediaQuery({
    fontSize: 12,
    fontWeight: 100,
    lineHeight: '16px',
    margin: '0 auto',
    width: ['100%', 920],
  }),
);

export const Small = styled(Text)(() =>
  mediaQuery({
    marginBottom: 16,
  }),
);
