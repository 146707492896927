import React, {useEffect, useState} from 'react';

import {Box} from 'rebass';
import {useHistory, useParams, useRouteMatch} from 'react-router-dom';
import PropTypes from 'prop-types';
import {isNil} from 'lodash';

import {lightGray} from '@renofi/utils/src/colors';
import {stringifyUrlParams} from '@renofi/utils/src/format';

import {StyledSelectField} from './styled';

const LoanTypeSelect = ({scenario}) => {
  const {loanProduct, products, lead} = scenario;

  const [value, setValue] = useState();
  const history = useHistory();
  const {path} = useRouteMatch();
  const {mock} = useParams();

  useEffect(() => {
    if (loanProduct.id) onChange(loanProduct.id);
  }, [loanProduct.id]);

  function getPath(value) {
    const isPublic = path.includes('public');
    if (isPublic) return `/public/product/${value}`;
    if (mock) return path.replace(':mock', value);
    return `/renovation/${lead?.calculatorToken}/product/${value}`;
  }

  function onChange(newValue) {
    setValue(newValue);
    const params = stringifyUrlParams();
    const newPath = getPath(newValue);
    history.push(`${newPath}?${params}`);
  }

  return (
    <Box
      pl={[0, 20]}
      css={{
        svg: {
          fill: lightGray,
        },
      }}>
      <StyledSelectField
        autoComplete="false"
        value={value}
        onChange={onChange}
        id="loanTypeSelect"
        name="loanTypeSelect">
        {isNil(value) ? (
          <option key="default">Please select ... </option>
        ) : null}
        {products.map((product) => (
          <option key={product.id} value={product.id} name={product.name}>
            {product.name}{' '}
            {!product.interestOnly ? `(${product.repaymentPeriod} years)` : ''}
          </option>
        ))}
      </StyledSelectField>
    </Box>
  );
};

LoanTypeSelect.propTypes = {
  scenario: PropTypes.object,
};

export default LoanTypeSelect;
