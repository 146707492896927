import React from 'react';

import PropTypes from 'prop-types';
import {animated, useSpring} from 'react-spring';

import {jumpIn, easyIn, find, superFast} from '@renofi/utils/src/animation';

import {ReactComponent as Hood} from './img/hood.svg';
import {ReactComponent as Island} from './img/island.svg';
import {ReactComponent as Table} from './img/table.svg';
import {ReactComponent as Fridge} from './img/fridge.svg';

const AnimatedHood = animated(Hood);
const AnimatedIsland = animated(Island);
const AnimatedTable = animated(Table);
const AnimatedFridge = animated(Fridge);

const Kitchen = ({breakpoint, isReturning}) => {
  return (
    <animated.div
      id="kitchen"
      style={{
        width: 160,
        height: 60,
        position: 'absolute',
        overflow: 'hidden',
        zIndex: 101,
        left: 100,
        top: 140,
      }}>
      <AnimatedHood
        style={{
          position: 'absolute',
          left: 35,
          ...useSpring({
            from: {marginTop: -20},
            to: {marginTop: 0},
            delay: 1500,
            config: easyIn,
          }),
        }}
      />
      <AnimatedIsland
        style={{
          position: 'absolute',
          top: 22,
          left: 3,
          ...useSpring({
            from: {marginTop: 210},
            to: {marginTop: 0},
            delay: 1200,
            config: jumpIn,
          }),
          ...useSpring({
            left: find([3, 3, 3, 3, 10], breakpoint),
            config: superFast,
          }),
        }}
      />
      <AnimatedTable
        style={{
          position: 'absolute',
          top: 40,
          left: 112,
          opacity: 0,
          ...useSpring({
            opacity: find([0.01, 0.01, 0.01, 0.01, 1], breakpoint),
            delay: isReturning ? 0 : 100,
            config: {duration: isReturning ? 0 : 200},
          }),
        }}
      />
      <AnimatedFridge
        style={{
          position: 'absolute',
          top: 22,
          left: 64,
          ...useSpring({
            from: {marginTop: 210},
            to: {marginTop: 0},
            delay: 1200,
            config: jumpIn,
          }),
          ...useSpring({
            left: find([64, 64, 64, 64, 84], breakpoint),
            config: superFast,
          }),
        }}
      />
    </animated.div>
  );
};

Kitchen.propTypes = {
  breakpoint: PropTypes.number,
  isReturning: PropTypes.bool,
};

export default Kitchen;
