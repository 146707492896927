import React from 'react';

import PropTypes from 'prop-types';
import {animated, useSpring} from 'react-spring';

import {find, jumpIn, superFast} from '@renofi/utils/src/animation';

import {ReactComponent as Lamp} from './img/lamp.svg';
import {ReactComponent as Bed} from './img/single-bed.svg';
import {ReactComponent as Sofa} from './img/sofa.svg';

const AnimatedLamp = animated(Lamp);
const AnimatedBed = animated(Bed);
const AnimatedSofa = animated(Sofa);

const Attic = ({breakpoint, isReturning}) => {
  return (
    <div
      id="attic"
      style={{
        width: 190,
        height: 67,
        position: 'absolute',
        overflow: 'hidden',
        zIndex: 101,
        left: 0,
        top: 5,
      }}>
      <AnimatedLamp
        style={{
          position: 'absolute',
          left: 90,
          ...useSpring({
            marginTop: find([-20, 0], breakpoint),
            config: {duration: 200},
          }),
        }}
      />
      <AnimatedSofa
        style={{
          position: 'absolute',
          left: 30,
          ...useSpring({
            from: {bottom: -30},
            to: {bottom: -3},
            delay: 1500,
            config: jumpIn,
          }),
          ...useSpring({
            marginBottom: find([-40, 0], breakpoint),
            config: {duration: 200},
          }),
        }}
      />
      <AnimatedBed
        style={{
          position: 'absolute',
          left: 120,
          ...useSpring({
            from: {bottom: -40},
            to: {bottom: -13},
            delay: 1500,
            config: jumpIn,
          }),
          ...useSpring({
            marginBottom: find([-40, 0], breakpoint),
            config: isReturning ? jumpIn : superFast,
          }),
        }}
      />
    </div>
  );
};

Attic.propTypes = {
  breakpoint: PropTypes.number,
  isReturning: PropTypes.bool,
};

export default Attic;
