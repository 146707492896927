import React, {memo, useState, useEffect} from 'react';

import {useHistory} from 'react-router-dom';
import {useMutation} from '@apollo/client';
import {Text} from 'rebass';

import {useEligibleZipCode, REQUEST_CALCULATOR_LINK} from '@renofi/api';
import {isOneHourOld} from '@renofi/utils/src/time';
import {sendEvent} from '@renofi/analytics';
import {Alert, Button, ZipInput} from '@renofi/components';
import {ReturnModal} from '@renofi/modules';
import {darkGray, lightGray} from '@renofi/utils/src/colors';
import {useLocalStorage, useKeyDown} from '@renofi/utils';

import {setNewHomeowner} from '../../api/cache';
import {GreenWrapper} from '../styled';

import {Arrow, Link, ZipWrapper} from './styled';

const Zip = () => {
  // Apollo
  const queryZipCode = useEligibleZipCode();
  const [resendEmail] = useMutation(REQUEST_CALCULATOR_LINK);
  // App
  const [zipCode, setZipCode] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailSuccess, setEmailSuccess] = useState(false);
  const [showReturnModal, setShowReturnModal] = useState(false);
  const history = useHistory();
  const [visited, setVisited] = useLocalStorage('visited');

  useKeyDown('Enter', onSubmit);

  useEffect(() => {
    sendEvent('Calculator/New-Homeowner/Zip-Page');
    if (visited && isOneHourOld(new Date(visited))) {
      setVisited(new Date());
      sendEvent('Calculator/New-Homeowner/Returning-User-Modal');
      setEmailSuccess(false);
      return setShowReturnModal(true);
    }
  }, []);

  async function onRequestLink(email) {
    await resendEmail({variables: {email}});
    setEmailSuccess(true);
    sendEvent('Calculator/New-Homeowner/Resend-Email-Success');
  }

  async function onSubmit() {
    if (!zipCode || error || loading) return;

    setLoading(true);
    setError(false);

    const {data = {}} = await queryZipCode({zipCode});
    const {eligibleZipCode} = data;

    if (!eligibleZipCode) {
      setError(true);
      setLoading(false);
      return;
    }

    const {city, state} = eligibleZipCode;
    setNewHomeowner({zipCode, city, state});

    setTimeout(() => history.push('/homeowner/financials'));
  }

  return (
    <>
      <Text fontSize={[28, 20]} mb={[30, 45]} mt={20} lineHeight="33px">
        Personalized monthly payment calculator
      </Text>

      <Text mt={[40, 0]} fontSize={[26, 28]} color={darkGray}>
        Enter your zip code to begin
      </Text>

      <ZipWrapper>
        <ZipInput
          onChange={() => setError(false)}
          error={error}
          onComplete={(value) => setZipCode(value)}
        />

        {error && (
          <Alert>
            This doesn&apos;t appear to be a valid zip code. Please double check
            the code and try again.
          </Alert>
        )}
      </ZipWrapper>

      <GreenWrapper alignItems="center">
        <Text textAlign="center" px={[10, 40]}>
          We use your location to match you with the most suitable lender & loan
          product available to you.
        </Text>

        <Button
          loading={loading}
          disabledBgColor={lightGray}
          disabledColor="black"
          disabled={!zipCode || error || loading}
          css={{marginTop: 20, minWidth: 220, textIndent: 25}}
          onClick={onSubmit}
          icon={Arrow}>
          <>Continue</>
        </Button>

        <Link style={{fontSize: 16}} to="/homeowner/help">
          Tell me more
        </Link>
      </GreenWrapper>

      <ReturnModal
        title="It looks like you’ve previously used the personalized monthly payment calculator"
        subtitle="Enter your email address below and we’ll resend your login link."
        show={showReturnModal}
        onClose={() => setShowReturnModal(false)}
        onSubmit={onRequestLink}
        success={emailSuccess}
      />
    </>
  );
};

export default memo(Zip);
