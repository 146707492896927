import React, {useEffect} from 'react';

import PropTypes from 'prop-types';

import {red} from '@renofi/utils/src/colors';
import {errorMessages} from '@renofi/utils/src/const';
import {callOnlyOnce} from '@renofi/utils';
import {sendEvent} from '@renofi/analytics';

import {Message} from '../TextField';

const LoanAmountError = ({show, analyticsPrefix, css}) => {
  useEffect(() => {
    if (show) {
      callOnlyOnce(() => sendEvent(`${analyticsPrefix}/Loan-Amount-Too-Low`));
    }
  }, [show]);

  if (!show) {
    return null;
  }

  return (
    <Message position="static" color={red} fontSize={13} css={css}>
      {errorMessages.minLoanAmount}
    </Message>
  );
};

LoanAmountError.propTypes = {
  show: PropTypes.bool,
  analyticsPrefix: PropTypes.string,
  css: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default LoanAmountError;
