import styled from '@emotion/styled/macro';
import {Box} from 'rebass';

import {mediaQuery} from '@renofi/utils';
import {darkBlue} from '@renofi/utils/src/colors';

const triangleRight = {
  borderTop: '8px solid transparent',
  borderBottom: '8px solid transparent',
  borderLeft: `8px solid ${darkBlue}`,
  top: 62,
  right: -8,
};

export const Wrapper = styled(Box)(
  mediaQuery({
    top: 75,
    opacity: 0,
    width: 200,
    position: 'absolute',
    zIndex: 104,
    left: ['calc(50% - 95px)', -220],
    transition: 'opacity .2s',
    ':before': {
      position: 'absolute',
      content: '" "',
      width: 0,
      height: 0,
      ...triangleRight,
    },
  }),
);
