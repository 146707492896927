import React, {useEffect, useState} from 'react';

import {noop, every, isNil} from 'lodash';
import PropTypes from 'prop-types';
import {Box, Flex, Text} from 'rebass';
import {Label} from '@rebass/forms';

import {useScreenSize} from '@renofi/utils';
import {sendEvent} from '@renofi/analytics';
import {gray, blue, lightGray, darkGray} from '@renofi/utils/src/colors';
import {
  capitalizeFirstLetter,
  isMonoCharacterString,
} from '@renofi/utils/src/format';
import {
  SelectField,
  TextField,
  PhoneField,
  Button,
  Message,
  PrivacyCheckbox,
  SubscribeCheckbox,
  StickyBottom,
} from '@renofi/components';
import {useIsEmailTaken, forEachCreditMap} from '@renofi/api';
import {forEachPurchaseStages} from '@renofi/api/src/enums';
import useRenovationUtils from '@renofi/utils/src/hooks/useRenovationUtils';

import ResendLink from '../../Tooltips/Help/ResendLink';
import {useNewHomeownerCache} from '../../api';

import {ReactComponent as PersonIcon} from './person-icon.svg';
import {TitleBar, Footer} from './styled';

const DetailsForm = ({formData, onChange, onClose = noop, onSubmit = noop}) => {
  const {newHomeowner} = useNewHomeownerCache();
  const {isPurchase} = useRenovationUtils(newHomeowner);
  const [errors, setErrors] = useState({});
  const [showEmailPopup, setShowEmailPopup] = useState(false);
  const [emailTaken, setEmailTaken] = useState(false);
  const queryEmailTaken = useIsEmailTaken();
  const {isMobile, isDesktop} = useScreenSize();

  useEffect(() => {
    sendEvent('Calculator/New-Homeowner/Final-Details-Modal');
    if (formData.email) {
      checkEmail(formData.email);
    }
  }, []);

  function onInputChange(key, value, err) {
    value.email && setEmailTaken(false);
    setErrors({...errors, [key]: err});
    onChange({[key]: value});

    if (key === 'email' && !formData.email) {
      checkEmail(value);
    }
  }

  function onEmailPopupClose() {
    setShowEmailPopup(false);
  }

  async function checkEmail(email) {
    const {data} = await queryEmailTaken({email});
    setEmailTaken(data?.isEmailTaken);
    if (data?.isEmailTaken) {
      setEmailTaken(true);
      setShowEmailPopup(true);
    }
  }

  function isFormValid() {
    return (
      every(errors, isNil) &&
      formData.firstName &&
      !isMonoCharacterString(' ', formData.firstName) &&
      formData.lastName &&
      !isMonoCharacterString(' ', formData.lastName) &&
      formData.email &&
      formData.phone &&
      formData.phone.length === 10 &&
      !emailTaken &&
      formData.selfReportedCreditScore &&
      formData.terms
    );
  }

  return (
    <>
      <Text>
        The information below is required to provide a truly personalized
        calculator experience including estimated{' '}
        <strong>minimum monthly payments</strong> and{' '}
        <strong>interest rates</strong>.
      </Text>

      <TitleBar>
        <Flex alignItems="center">
          <PersonIcon />
          <Text ml={10} color={darkGray} fontSize={20}>
            Your details
          </Text>
        </Flex>

        <Text color={blue} fontSize={14}>
          All details are required unless stated
        </Text>
      </TitleBar>

      <Flex mx={-2} my={[20, 25]} flexDirection={['column', 'row']}>
        <Box width={[1, 1 / 2]} px={2} mb={[20, 0]}>
          <Label color={gray} htmlFor="firstName">
            First name
          </Label>
          <TextField
            noSpecialCharacters
            value={formData.firstName}
            onChange={(value, error) =>
              onInputChange('firstName', value, error)
            }
            id="firstName"
            name="firstName"
            type="text"
          />
        </Box>
        <Box width={[1, 1 / 2]} px={2}>
          <Label color={gray} htmlFor="lastName">
            Last name
          </Label>
          <TextField
            noSpecialCharacters
            value={formData.lastName}
            onChange={(value, error) => onInputChange('lastName', value, error)}
            id="lastName"
            name="lastName"
            type="text"
          />
        </Box>
      </Flex>

      <Flex mx={-2} my={[20, 25]} flexDirection={['column', 'row']}>
        <Box width={[1, 1 / 2]} px={2} mb={[20, 0]}>
          <Label color={gray} htmlFor="phone">
            Phone
          </Label>
          <PhoneField
            required
            value={formData.phone}
            onChange={(value, err) => onInputChange('phone', value, err)}
            id="phone"
            name="phone"
            type="tel"
          />
        </Box>

        <Box
          width={[1, 1 / 2]}
          px={2}
          mb={[25, 0]}
          css={{position: 'relative'}}>
          <Label color={gray} htmlFor="email">
            Email
          </Label>
          <TextField
            email
            value={formData.email}
            onChange={(value, error) => onInputChange('email', value, error)}
            onBlur={checkEmail}
            message="We’ll send a link to this email so you can view your personalized
              calculator at anytime."
            error={
              emailTaken && !showEmailPopup ? (
                <>
                  Previously used email address.{' '}
                  <a onClick={() => setShowEmailPopup(true)}>
                    Resend login link
                  </a>
                </>
              ) : (
                ''
              )
            }
            id="email"
            name="email"
            type="email"
          />
          {showEmailPopup && (
            <ResendLink email={formData.email} onClose={onEmailPopupClose} />
          )}
        </Box>
      </Flex>

      <Flex mx={-2} mt={[0, 48]} flexDirection={['column', 'row']} pb={40}>
        <Box width={[1, 1 / 2]} px={2} css={{position: 'relative'}}>
          <Label color={gray} htmlFor="selfReportedCreditScore">
            How would you describe your credit?
          </Label>
          <SelectField
            autoComplete="false"
            value={formData.selfReportedCreditScore}
            onChange={(value) =>
              onInputChange('selfReportedCreditScore', value)
            }
            id="selfReportedCreditScore"
            name="selfReportedCreditScore">
            {formData.selfReportedCreditScore ? null : <option />}
            {forEachCreditMap((key, value) => (
              <option key={key} value={key}>
                {value}
              </option>
            ))}
          </SelectField>
          <Message align={isDesktop ? 'right' : 'left'}>
            Don’t worry, we don’t pull credit.
          </Message>
        </Box>

        {isPurchase ? (
          <Box
            width={[1, 1 / 2]}
            px={2}
            mt={[32, 0]}
            css={{position: 'relative'}}>
            <Label color={gray} htmlFor="selfReportedCreditScore">
              The current stage of the purchase is
            </Label>
            <SelectField
              autoComplete="false"
              value={formData.stageOfPurchase}
              onChange={(value) => onInputChange('stageOfPurchase', value)}
              id="stageOfPurchase"
              name="stageOfPurchase">
              {formData.loanSubject ? null : <option />}
              {forEachPurchaseStages((key, value) => (
                <option key={key} value={key}>
                  {capitalizeFirstLetter(value.replaceAll('_', ' '))}
                </option>
              ))}
            </SelectField>
          </Box>
        ) : null}
      </Flex>

      <SubscribeCheckbox
        onChange={onInputChange}
        value={Boolean(formData.hasNewsletterConsent)}
      />
      <PrivacyCheckbox
        onChange={(value) => onInputChange('terms', value)}
        value={Boolean(formData.terms)}
      />

      {isDesktop && (
        <Footer>
          <Button
            bgColor="white"
            color={gray}
            borderColor={lightGray}
            xSmall
            onClick={onClose}>
            Close
          </Button>

          <Button
            disabled={!isFormValid()}
            small
            onClick={() => onSubmit(formData)}>
            View payments + rates
          </Button>
        </Footer>
      )}

      {isMobile && (
        <StickyBottom>
          <Button disabled={!isFormValid()} onClick={() => onSubmit(formData)}>
            View payments + rates
          </Button>
        </StickyBottom>
      )}
    </>
  );
};

DetailsForm.propTypes = {
  formData: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default DetailsForm;
