import {useMutation} from '@apollo/client';

import {REQUEST_CALCULATOR_LINK} from '../mutations/requestCalculatorLink';

export default function useRequestCalculatorLink() {
  const [requestCalculatorLink, {data, loading, error}] = useMutation(
    REQUEST_CALCULATOR_LINK,
  );
  const response = data?.requestCalculatorLink;
  return {requestCalculatorLink, response, loading, error};
}
