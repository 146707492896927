import {makeVar} from '@apollo/client';

// Reactive variables
export const calculatedValuesVar = makeVar({
  maxLoan: 0,
  monthlyPayment: 0,
  isMaxLoanFromDti: false,
});

// Setter
export const setCalculatedValues = (values) => {
  calculatedValuesVar({...calculatedValuesVar(), ...values});
};

// Apollo field policy
export const calculatedValuesPolicy = {
  read() {
    return calculatedValuesVar();
  },
};
