import styled from '@emotion/styled/macro';
import {Flex} from 'rebass';

import {fadeGreen} from '@renofi/utils/src/colors';

import {Label, Input, InputWrapper} from '../../components/Slider/styled';

export const Footer = styled(Flex)({
  justifyContent: 'space-between',
  alignItems: 'baseline',
  borderRadius: '0 0 18px 18px',
  marginBottom: -20,
  paddingBottom: 20,
  padding: '0 20px 20px 20px',
});

export const customSliderStyles = {
  paddingBottom: 3,
  ':hover': {
    background: fadeGreen,
  },
  [Label]: {
    fontSize: 16,
  },
  [Input]: {
    fontSize: 36,
  },
  [InputWrapper]: {
    ':before': {
      fontSize: 28,
    },
  },
};
