import React, {memo} from 'react';

import PropTypes from 'prop-types';
import {Box, Image, Link, Text} from 'rebass';
import {has} from 'lodash';

import {
  mediaQuery,
  constants,
  getRaasPartnerName,
  useUTMTags,
  useRaasSourceLender,
} from '@renofi/utils';
import {renofiLogoDarkIconSrc} from '@renofi/components';
import {RAAS_PARTNER_LOGOS} from '@renofi/modules';
import {RAAS_PARTNERS_CUSTOM_WIDTH} from '@renofi/utils/src/const';

import {useScenario, useNewHomeownerCache} from '../api';

import renofiIcon from './renofi-house.svg';
import {Logo, Borrower, Wrapper} from './styled';

const Header = () => {
  // Apollo
  const {scenario} = useScenario();
  const {newHomeowner} = useNewHomeownerCache();
  const lead = scenario.lead || newHomeowner;
  // App
  const {utmTags} = useUTMTags(constants.UTM_COOKIE_KEYS.renofi);
  const raasSourceLender = useRaasSourceLender(utmTags);
  const raasLender = lead.sourceLenderCode || raasSourceLender;

  return (
    <header>
      <Wrapper justifyContent="space-between" alignItems="center">
        <Link href="https://www.renofi.com" target="_blank">
          <Logo src={renofiLogoDarkIconSrc} alt="RenoFi" />
          {has(RAAS_PARTNER_LOGOS, raasLender) && (
            <Logo
              width={RAAS_PARTNERS_CUSTOM_WIDTH[raasLender]}
              src={RAAS_PARTNER_LOGOS[raasLender]}
              alt={getRaasPartnerName(raasLender)}
            />
          )}
        </Link>
        {lead.firstName ? (
          <Borrower>
            <Box>
              <Text fontSize={18}>{lead.firstName}</Text>
            </Box>
            <Image
              css={mediaQuery({
                display: ['none', 'inherit'],
                width: 40,
                height: 40,
                marginLeft: 10,
              })}
              src={renofiIcon}
              alt="RenoFi"
            />
          </Borrower>
        ) : null}
      </Wrapper>
    </header>
  );
};

Header.propTypes = {
  project: PropTypes.object,
};

export default memo(Header);
