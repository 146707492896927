import React from 'react';

import PropTypes from 'prop-types';
import {RenderPropSticky as StickyReactEl} from 'react-sticky-el';

import {useScreenSize} from '@renofi/utils';

const Sticky = ({children, stickTo}) => {
  const {isDesktop} = useScreenSize();

  if (isDesktop) {
    return children(false);
  }

  return (
    <StickyReactEl boundaryElement={stickTo} dontUpdateHolderHeightWhenSticky>
      {({isFixed, holderRef, holderStyles, wrapperRef, wrapperStyles}) => {
        return (
          <div ref={holderRef} style={{...holderStyles}}>
            <div
              style={{
                position: 'relative',
                ...wrapperStyles,
                zIndex: isFixed ? 203 : 1,
              }}
              ref={wrapperRef}>
              {children(isFixed)}
            </div>
          </div>
        );
      }}
    </StickyReactEl>
  );
};

Sticky.propTypes = {
  children: PropTypes.func,
  stickTo: PropTypes.string,
};

export default Sticky;
