import React from 'react';

import {PanelDark} from '@renofi/components';

import {Text} from '../styled';

const CurrentValueBuild = () => {
  return (
    <PanelDark title="Estimated current property value">
      <Text mb={10}>
        What is the estimated current value of the property where the build is
        taking place? If you’re building on land, indicate the estimated current
        value of the land.
      </Text>
      <Text>
        The current value of the property contributes to the maximum loan amount
        that you can borrow.
      </Text>
    </PanelDark>
  );
};

export default CurrentValueBuild;
