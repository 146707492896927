import React from 'react';

import PropTypes from 'prop-types';
import {Box} from 'rebass';
import {isNil} from 'lodash';

import {LoanAmountError} from '@renofi/components';
import {useScreenSize} from '@renofi/utils';

import {Slider} from '../../../components';
import {customSliderStyles} from '../styled';
import useLabels from '../../../utils/useLabels';

const PurchaseFormInputs = ({
  lead,
  onChange,
  setDirty,
  isMinLoanInvalid,
  selected,
  setSelected,
}) => {
  const {isDesktop} = useScreenSize();
  const labels = useLabels(lead);
  const common = {
    large: isDesktop,
    css: customSliderStyles,
    noSlider: true,
  };

  return (
    <>
      <Slider
        inputProps={{min: 10000, limit: 9}}
        keyIndex={2}
        selected={selected === 2}
        label={labels.propertyPurchasePrice}
        onFocus={() => setSelected(2)}
        onChange={(value) => onChange({propertyPurchasePrice: value})}
        value={lead.propertyPurchasePrice || ''}
        {...common}
      />
      <Slider
        keyIndex={3}
        inputProps={{limit: 9}}
        selected={selected === 3}
        label={labels.estimatedDownpayment}
        onFocus={() => setSelected(3)}
        onChange={(value) => onChange({estimatedDownpayment: value})}
        value={
          !isNil(lead.estimatedDownpayment) ? lead.estimatedDownpayment : ''
        }
        {...common}
      />
      <Slider
        keyIndex={4}
        inputProps={{limit: 9}}
        selected={selected === 4}
        label={labels.renovationCost}
        onFocus={() => setSelected(4)}
        onChange={(value) => onChange({renovationCost: value})}
        onBlur={() => setDirty(true)}
        value={lead.renovationCost || ''}
        error={isMinLoanInvalid()}
        {...common}>
        <Box mt="-5px" pb="5px">
          <LoanAmountError
            show={isMinLoanInvalid()}
            analyticsPrefix="Calculator"
          />
        </Box>
      </Slider>

      <Slider
        keyIndex={5}
        inputProps={{min: 10000, limit: 9}}
        selected={selected === 5}
        label={labels.afterRenovatedValue}
        onFocus={() => setSelected(5)}
        onBlur={() => setSelected(5)}
        onChange={(value) => onChange({afterRenovatedValue: value})}
        value={lead.afterRenovatedValue || ''}
        {...common}
      />
    </>
  );
};

PurchaseFormInputs.propTypes = {
  lead: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  setDirty: PropTypes.func.isRequired,
  isMinLoanInvalid: PropTypes.func.isRequired,
  selected: PropTypes.number,
  setSelected: PropTypes.func.isRequired,
};

export default PurchaseFormInputs;
