import {useEffect, useState} from 'react';

import {Box, Text} from 'rebass';

import {Toggle} from '@renofi/components';
import {useScreenSize} from '@renofi/utils';
import {blue} from '@renofi/utils/src/colors';
import {useTopLoanProducts} from '@renofi/api';

import Calculator from '../Calculator';
import {useScenario} from '../api';
import Banner from '../Banner';
import {Container, Content} from '../Page/styled';

import {PublicContainer, Small, PublicFooter} from './styled';

const fakeScenario = {
  lead: {
    afterRenovatedValue: 0,
    homeValue: 0,
    firstMortgageBalance: 0,
    secondaryMortgagePayment: 0,
  },
  initialProduct: {rates: [0]},
};

const Public = () => {
  const [showMore, setShowMore] = useState(false);
  const [products, setProducts] = useState([]);
  const {scenario} = useScenario(
    {
      result: {...fakeScenario, calculatorLoanProducts: products},
    },
    products?.length,
    true,
  );
  const fetchTopProducts = useTopLoanProducts();
  const {isDesktop} = useScreenSize();

  useEffect(() => {
    (async () => {
      const rsp = await fetchTopProducts();
      const {topLoanProducts} = rsp?.data || {};
      setProducts(topLoanProducts);
    })();
  }, []);

  if (!products?.length) {
    return null;
  }

  return (
    <div>
      <Container>
        <Content bg="white" mt={[0, '16px']}>
          <Toggle show={isDesktop}>
            <Banner title="Renovation Financing Calculator" />
          </Toggle>
          <Calculator scenario={scenario} isPublic />
        </Content>
      </Container>

      <PublicFooter>
        <PublicContainer>
          <Small>
            The renovation financing calculator is illustrative and intended for
            educational purposes only. The information provided does not
            represent all available financing options in the market, nor does it
            ensure the availability or eligibility for any specific product,
            rate, loan amount or other loan term. Actual available rates, loan
            products, loan amounts and other loan terms are subject to change
            and determined by lenders based on an evaluation of credit, income
            and other information provided in a loan application with the
            lender. Payment amounts do not reflect any taxes, insurance, PMI or
            other fees that may apply.
          </Small>

          <Small>
            The calculator tool is presented by RenoFi and is not affiliated
            with nor receives any compensation from the company where this
            calculator was linked from or presented.{' '}
            <Text
              as="span"
              onClick={() => setShowMore(true)}
              css={{cursor: 'pointer', color: blue}}>
              Learn More
            </Text>
          </Small>

          <Toggle show={showMore}>
            <Box>
              <Small>
                RenoFi is a financial technology company that operates an online
                marketplace that allows homeowners to view various financing
                options for residential home renovation projects. RenoFi is a
                mortgage and personal loan broker specializing in home
                renovation financing; RenoFi is not a lender. RenoFi does not
                make loans or credit decisions in connection with loans, nor
                does it issue loan commitments or rate lock-in agreements. Loan
                products arranged by RenoFi are not available in all states, and
                terms and conditions apply, including acceptable membership
                requirements stipulated by third-party credit union lenders.
                Closing costs apply and are determined by the lender.
              </Small>
              <Small>
                RenoFi works with select credit union lending partners to create
                a proprietary loan product, RenoFi Loan, which leverages a
                home’s After Renovation Value to calculate the loan amount
                resulting in greater borrowing power. RenoFi Loans are presented
                in this calculator tool as Renovation HELOCs, Renovation
                HELOANs, and Home Improvement Personal Loans.
              </Small>
              <Small>
                Renovation Finance LLC DBA RenoFi (NMLS # 1802847). RenoFi
                operates as Renovation Technologies Holdings Inc. in California
                (NMLS # 2412747), Renovation Technologies LLC in Nebraska, and
                RenoFi LLC in New Mexico.{' '}
                <strong>
                  CA Department of Real Estate Broker License # 02195141
                </strong>
                , CA Financing Law License # 60DBO-148207, ME Loan Broker
                License # 1802847.
              </Small>
            </Box>
          </Toggle>
        </PublicContainer>
      </PublicFooter>
    </div>
  );
};

export default Public;
