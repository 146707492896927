import styled from '@emotion/styled/macro';
import {Box} from 'rebass';

import {mediaQuery} from '@renofi/utils';
import {darkBlue} from '@renofi/utils/src/colors';
import {Button} from '@renofi/components';

export const Wrapper = styled(Box)({
  width: 360,
  position: 'absolute',
  left: -2,
  zIndex: 100,
  bottom: 55,
  transition: 'top .2s, opacity .2s',
  opacity: 0.97,
  ':before': {
    position: 'absolute',
    bottom: -8,
    left: 182,
    content: '" "',
    width: 0,
    height: 0,
    borderLeft: '8px solid transparent',
    borderRight: '8px solid transparent',
    borderTop: `8px solid ${darkBlue}`,
  },
});

export const CloseButton = styled(Button)(
  mediaQuery({
    padding: 0,
    position: 'absolute',
    top: [8, 0],
    right: [8, 0],
    height: [24, 24],
    width: [24, 24],
  }),
);
