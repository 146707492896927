// returns max cashout amount based on maximizing the consumer's DTI to fit
// inside the maximum lender guideline
function getMortgagePayments({
  productType,
  monthlyDebt,
  secondaryMortgagePayment,
  primaryMortgagePayment,
}) {
  const excludeFirstMortgage =
    productType === 'renofi_cashout_refi' ||
    productType === 'traditional_cashout_refi';
  const excludeSecondMortgage =
    productType === 'renofi_heloc' ||
    productType === 'renofi_home_equity' ||
    productType === 'renofi_cashout_refi' ||
    productType === 'traditional_heloc' ||
    productType === 'traditional_home_equity';

  if (excludeFirstMortgage && excludeSecondMortgage)
    return monthlyDebt - primaryMortgagePayment - secondaryMortgagePayment;
  if (!excludeFirstMortgage && excludeSecondMortgage)
    return monthlyDebt - secondaryMortgagePayment;
  return monthlyDebt;
}

// using both the cashout + refi (total loan amount) so that we calculate the
// max the homeowner can afford, but returning the cashout amount
function calculateMaxCashoutFromDTI({
  terms,
  exampleRate,
  monthlyDebt,
  annualIncomePretax,
  maxDti,
  secondaryMortgagePayment = 0,
  primaryMortgagePayment = 0,
  productType,
}) {
  const calculatedMonthlyDebt = getMortgagePayments({
    productType,
    monthlyDebt,
    secondaryMortgagePayment,
    primaryMortgagePayment,
  });
  const maxMonthlyPayment = calculateMaxMonthlyPayment({
    monthlyDebt: calculatedMonthlyDebt,
    annualIncomePretax,
    maxDti,
  });
  const numPayments = terms * 12; // qualifying payments, do not use interest only payments for DTI
  const monthlyRate = exampleRate / 1200;
  return (
    (maxMonthlyPayment * (1 - (1 + monthlyRate) ** (-1 * numPayments))) /
    monthlyRate
  );
}

// returns the maximum new monthly payment the consumer could afford based on lender guidelines (maxDti)
function calculateMaxMonthlyPayment({monthlyDebt, annualIncomePretax, maxDti}) {
  const monthlyIncome = annualIncomePretax / 12;
  return maxDti * monthlyIncome - monthlyDebt;
}

// returns the max cashout amount based on Peak CLTV and ARV CLTV, returning the lower of the 2 loan amounts
function calculateMaxCashoutFromLTV({
  maxPeakCltv,
  maxArvCltv,
  afterRenovatedValue,
  homeValue,
  totalMortgageBalance = 0,
  productType,
}) {
  const isProductWithoutARV = productType?.includes('traditional');

  const maxAmountARVCLTV =
    maxArvCltv * afterRenovatedValue - totalMortgageBalance;
  const currentLTV = totalMortgageBalance / homeValue;
  const maxAmountPeakCLTV =
    Math.max(currentLTV, maxPeakCltv) * homeValue - totalMortgageBalance;

  if (!maxPeakCltv) {
    return maxAmountARVCLTV;
  }
  if (isProductWithoutARV || !maxArvCltv) {
    return maxAmountPeakCLTV;
  }

  return Math.min(maxAmountARVCLTV, maxAmountPeakCLTV);
}

export function canCalculateWithDti(args) {
  return Boolean(
    args.maxDti &&
      args.maxArvCltv &&
      args.maxPeakCltv &&
      args.monthlyDebt &&
      args.annualIncomePretax &&
      args.exampleRate &&
      args.maxLoanAmount &&
      args.terms &&
      args.afterRenovatedValue &&
      args.totalMortgageBalance &&
      (args.homeValue || args.propertyPurchasePrice),
  );
}

// calculates the max "cashout" portion of the loan
// (the loan amount sought for renovation project)
export default function calculateMaxLoanFromDtiAndLtv(args) {
  const {firstMortgageBalance, secondMortgageBalance} = args;
  if (firstMortgageBalance === 0) args.primaryMortgagePayment = 0;
  if (secondMortgageBalance === 0) args.secondaryMortgagePayment = 0;

  if (args.maxPurchaseLtv && args.propertyPurchasePrice) {
    return Math.min(
      args.maxLoanAmount,
      args.propertyPurchasePrice * args.maxPurchaseLtv,
    );
  }

  const newArgs = {
    ...args,
    totalMortgageBalance:
      (firstMortgageBalance || 0) + (secondMortgageBalance || 0),
  };

  const isPersonalLoan = args.productType === 'unsecured_personal';
  const maxLoanByLTV = isPersonalLoan
    ? args.maxLoanAmount
    : calculateMaxCashoutFromLTV(newArgs);

  const maxLoanByDTI = canCalculateWithDti(newArgs)
    ? calculateMaxCashoutFromDTI(newArgs)
    : args.maxLoanAmount;

  const maxLoan = Math.min(maxLoanByDTI, maxLoanByLTV, newArgs.maxLoanAmount);
  return maxLoan > 0 ? Math.round(maxLoan / 1000) * 1000 : 0;
}
