import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {pick, isEmpty, size} from 'lodash';

import {
  calculateMaxLoan,
  calculateMaxLoanFromDtiAndLtv,
  constants,
  useLocalStorage,
  useMaxValues,
  useRaasSourceLender,
  useUTMTags,
} from '@renofi/utils';
import {fadeGreen} from '@renofi/utils/src/colors';
import {canCalculateWithDti} from '@renofi/utils/src/calculateMaxLoanFromDti';
import {Sticky, Toggle} from '@renofi/components';
import useRenovationUtils from '@renofi/utils/src/hooks/useRenovationUtils';
import {getRaasPartnerByCode} from '@renofi/utils/src/const';

import {SectionSummary, Slider} from '../../components';
import {useNewHomeownerCache} from '../../api';
import {setCalculatedValues, setMortgageBalance} from '../../api/cache';
import {useUSAllianceMock} from '../../api/hooks';
import useLabels from '../../utils/useLabels';
import {Tooltip} from '../../Tooltips';
import {getMaxLoanTitle} from '../../utils';

import SecondMortgageSlider from './SecondMortgageSlider';

const MaxLoan = ({
  isPublic,
  isPreview,
  showSummary = true,
  scenario = {},
  onChange,
}) => {
  // Apollo
  const {newHomeowner} = useNewHomeownerCache();
  const {loanProduct, lender} = scenario;
  const lead = isEmpty(scenario.lead) ? newHomeowner : scenario.lead;
  const {isPurchase: isPurchaseScenario, isHomeBuild} =
    useRenovationUtils(lead);

  const {utmTags} = useUTMTags(constants.UTM_COOKIE_KEYS.renofi);
  const raasSourceLender = useRaasSourceLender(utmTags);
  const raasLender = lead.sourceLenderCode || raasSourceLender || '';
  const raasParnerObject = getRaasPartnerByCode(raasLender) || {};

  const isPurchaseOnlyProduct = loanProduct?.productType === 'purchase_loan';
  const isProductWithoutARV = loanProduct?.productType?.includes('traditional');
  const isPurchaseAndRenovationProduct =
    loanProduct?.productType === 'purchase_and_renovate_loan';
  // App
  const [state, setState] = useState({});
  const maxValues = useMaxValues(lead);
  const [, setVisited] = useLocalStorage('visited', false);
  const {isUSAllianceMock} = useUSAllianceMock();
  const labels = useLabels(lead);

  useEffect(() => {
    if (scenario?.lead?.email) {
      setVisited(new Date());
    }
    const values = size(state) ? state : lead;
    if (lead) {
      onValueChange({
        ...pick(values, [
          'propertyPurchasePrice',
          'afterRenovatedValue',
          'homeValue',
          'firstMortgageBalance',
          'secondaryMortgagePayment',
          'estimatedDownpayment',
        ]),
        secondMortgageBalance: isUSAllianceMock
          ? null
          : values.secondMortgageBalance,
      });
    }
  }, [loanProduct?.name]);

  function onValueChange(value) {
    const newState = {...state, ...value};
    const calculatedMortgageBalance = Math.max(
      newState.propertyPurchasePrice - newState.estimatedDownpayment,
      0,
    );
    const args = {
      ...lead,
      ...newState,
      ...loanProduct,
      ...(isHomeBuild
        ? {
            homeValue: newState.afterRenovatedValue,
          }
        : {}),
      ...(isPurchaseScenario
        ? {
            homeValue: newState.homeValue || newState.propertyPurchasePrice,
            firstMortgageBalance: calculatedMortgageBalance,
          }
        : {}),
      ...(isPurchaseAndRenovationProduct ? {firstMortgageBalance: 0} : {}),
      ...(raasParnerObject?.maxArvCltv
        ? {maxArvCltv: raasParnerObject.maxArvCltv}
        : {}),
    };

    const isMaxLoanFromDti = canCalculateWithDti(args);
    const maxLoan = isPreview
      ? calculateMaxLoan(args)
      : calculateMaxLoanFromDtiAndLtv(args);
    setState({...newState, maxLoan});
    setCalculatedValues({...newState, maxLoan, isMaxLoanFromDti});
    onChange && onChange(state);
  }

  function onFirstMortgageBalanceChange(newValue) {
    onValueChange({firstMortgageBalance: newValue});
    setMortgageBalance({firstMortgageBalance: newValue});
  }

  return (
    <div
      className="max-loan"
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        marginBottom: showSummary ? '0' : '5px',
        height: showSummary ? 'calc(100% - 65px)' : 'calc(100% - 5px)',
      }}>
      <Toggle show={showSummary}>
        <Sticky stickTo=".max-loan">
          {(isSticky) => (
            <SectionSummary
              isSticky={isSticky}
              isPublic={isPublic}
              title={getMaxLoanTitle(loanProduct)}
              value={state.maxLoan || 0}
              color={fadeGreen}
              loanProduct={loanProduct}
              lender={lender}
            />
          )}
        </Sticky>
      </Toggle>
      {isProductWithoutARV || isPurchaseOnlyProduct ? null : (
        <Slider
          step={5000}
          label={labels.afterRenovatedValue}
          min={0}
          max={maxValues.afterRenovatedValue}
          onChange={(value) => onValueChange({afterRenovatedValue: value})}
          value={state.afterRenovatedValue}
        />
      )}
      <Slider
        css={{marginTop: showSummary ? 5 : 0}}
        step={5000}
        label={labels.propertyPurchasePrice}
        min={0}
        max={maxValues.propertyPurchasePrice}
        onChange={(value) => onValueChange({propertyPurchasePrice: value})}
        value={state.propertyPurchasePrice}
      />
      <Slider
        css={{
          marginTop: showSummary ? 5 : 0,
          borderRadius: isPublic || isPreview ? '0 0 0 16px' : '0',
        }}
        step={5000}
        label={labels.estimatedDownpayment}
        min={0}
        max={maxValues.estimatedDownpayment}
        onChange={(value) => onValueChange({estimatedDownpayment: value})}
        value={state.estimatedDownpayment}
      />
      <Slider
        css={{
          marginTop: showSummary ? 5 : 0,
          height: isPurchaseScenario ? '100%' : 'auto',
        }}
        step={5000}
        label={labels.homeValue || 'Current home value'}
        min={0}
        max={maxValues.homeValue}
        onChange={(value) => onValueChange({homeValue: value})}
        value={state.homeValue}
      />
      {!isPreview && (
        <SecondMortgageSlider
          value={state.secondMortgageBalance}
          onChange={onValueChange}
        />
      )}
      <Toggle show={!isPurchaseScenario}>
        <Slider
          tooltip={
            lead.loanSubject === 'renovate_or_build_adu' ? (
              <Tooltip id="Estimated mortgage balance on the property with ADU" />
            ) : undefined
          }
          css={{
            borderRadius: isPublic || isPreview ? '0 0 0 16px' : '0',
            height: '100%',
          }}
          step={5000}
          label={labels.firstMortgageBalance}
          min={0}
          max={maxValues.firstMortgageBalance}
          onChange={(value) => onFirstMortgageBalanceChange(value)}
          value={state.firstMortgageBalance}
        />
      </Toggle>
    </div>
  );
};

MaxLoan.propTypes = {
  isPublic: PropTypes.bool,
  isPreview: PropTypes.bool,
  showSummary: PropTypes.bool,
  scenario: PropTypes.object,
  onChange: PropTypes.func,
};

export default MaxLoan;
