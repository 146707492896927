import React, {useState, useEffect} from 'react';

import PropTypes from 'prop-types';
import {Flex} from 'rebass';
import {isArray} from 'lodash';

import TermsSlider from '../TermsSlider';
import RatesSlider from '../RatesSlider';

const Terms = ({onChange, selectedTerm, selectedRate, scenario}) => {
  const {
    positionedInterestRate,
    isPreferredProduct,
    loanProduct,
    initialRates,
  } = scenario;

  // App
  const [sliderRates, setSliderRates] = useState(initialRates);

  useEffect(() => {
    setSliderRates(getRates(initialRates));
  }, [initialRates?.toString()]);

  const {variants} = loanProduct;

  let repaymentPeriods = isArray(variants)
    ? variants.map((variant) => variant.repaymentPeriod)
    : [loanProduct.repaymentPeriod];
  repaymentPeriods = repaymentPeriods.sort((a, b) => a - b);

  function qualifiesForPreferredTerm(term = selectedTerm) {
    return (
      positionedInterestRate &&
      isPreferredProduct &&
      term === loanProduct?.repaymentPeriod
    );
  }

  function getRates(rates, term) {
    return [
      ...new Set([
        ...rates,
        ...(qualifiesForPreferredTerm(term) ? [positionedInterestRate] : []),
      ]),
    ].sort();
  }

  function getRatesForTerm(term) {
    const variantRates = variants.find(
      (variant) => variant.repaymentPeriod === term,
    ).rates;
    return getRates(variantRates, term);
  }

  function onTermsChange(newTerm) {
    const newRates = getRatesForTerm(newTerm);
    const currentIndex = sliderRates.indexOf(selectedRate);
    const newRate = newRates[currentIndex];

    setSliderRates(newRates);
    onChange({
      selectedTerm: newTerm,
      selectedRate: newRate,
    });
  }

  return (
    <Flex css={{height: '100%'}} flexDirection="column">
      {repaymentPeriods?.length > 1 && (
        <TermsSlider
          onChange={onTermsChange}
          value={selectedTerm}
          terms={repaymentPeriods}
        />
      )}

      {sliderRates && (
        <RatesSlider
          css={{height: 'calc(100% - 5px)'}}
          value={selectedRate}
          rates={sliderRates}
          onChange={onChange}
        />
      )}
    </Flex>
  );
};

Terms.propTypes = {
  onChange: PropTypes.func,
  selectedTerm: PropTypes.number,
  selectedRate: PropTypes.number,
};

export default Terms;
