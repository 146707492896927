import styled from '@emotion/styled/macro';
import {Box} from 'rebass';

import {mediaQuery} from '@renofi/utils';

export const Container = styled(Box)(
  mediaQuery({
    background: ['white', 'none'],
    boxSizing: ['border-box', 'content-box'],
    margin: '0 auto',
  }),
  ({embed}) =>
    mediaQuery({
      border: embed ? `solid 3px #e7e7e7` : 'none',
      padding: [0, embed ? 0 : '8px 20px 20px 20px'],
      width: [`100%`, embed ? 'calc(100% - 6px)' : 920],
    }),
);

export const Content = styled(Box)(
  mediaQuery({
    borderRadius: 16,
  }),
  ({embed}) =>
    mediaQuery({
      boxShadow: embed ? 'none' : ['none', '0 0 40px 0 rgba(68,68,68,0.11)'],
    }),
);
