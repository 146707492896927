import styled from '@emotion/styled/macro';

import {mediaQuery} from '@renofi/utils';

export const Wrapper = styled.div(
  mediaQuery({
    color: 'white',
    padding: ['10px 15px', 15],
    marginBottom: 5,
    span: {
      paddingLeft: 10,
    },
  }),
  ({color, css}) =>
    mediaQuery({
      background: color,
      ...css,
    }),
);
