import styled from '@emotion/styled/macro';
import {Flex, Image} from 'rebass';

import {mediaQuery} from '@renofi/utils';

export const Logo = styled(Image)(
  mediaQuery({
    height: 'auto',
    marginLeft: [15],
  }),
  ({width}) => ({
    width: width ? width : [100, 130],
  }),
);

export const SimpleHeaderWrapper = styled(Flex)(
  mediaQuery({
    justifyContent: ['start', 'center'],
    alignItems: ['start', 'center'],
    background: ['#F7FCFE', 'none'],
    height: [70, 'auto'],
    paddingTop: [20, 'auto'],
    borderBottom: ['solid 1px #EFEFEF', 'none'],
  }),
  ({css}) => mediaQuery(css),
);
