import React from 'react';

import {Box} from 'rebass';
import PropTypes from 'prop-types';
import ReactCarousel from 'react-multi-carousel';

import 'react-multi-carousel/lib/styles.css';
import {breakpoint} from '@renofi/utils/src/mediaQuery';
import {useScreenSize} from '@renofi/utils';

import Buttons from './Buttons';
import {Dot} from './styled';

const responsive = {
  desktop: {
    breakpoint: {max: 4000, min: breakpoint},
    items: 3,
  },
  mobile: {
    breakpoint: {max: breakpoint, min: 0},
    items: 1,
  },
};

const Carousel = ({children}) => {
  const {isMobile} = useScreenSize();

  return (
    <Box css={{position: 'relative'}} mx="-5px">
      <ReactCarousel
        infinite
        arrows={false}
        responsive={responsive}
        customButtonGroup={<Buttons />}
        renderButtonGroupOutside
        customDot={<Dot />}
        renderDotsOutside
        customTransition={isMobile ? 'all .2s ease-out' : null}
        showDots>
        {children}
      </ReactCarousel>
    </Box>
  );
};

Carousel.propTypes = {
  children: PropTypes.node,
};

export default Carousel;
