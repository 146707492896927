import styled from '@emotion/styled/macro';
import {Box, Image, Flex, Text, Link as RebassLink} from 'rebass';

import {lightGray, red} from '@renofi/utils/src/colors';
import {mediaQuery} from '@renofi/utils';

import linkIcon from './img/link.svg';
import speechBubble from './img/speech-bubble.svg';

export const WhatNextWrapper = styled(Flex)(
  mediaQuery({
    alignItems: 'flex-end',
    flexDirection: 'column',
  }),
  ({showQuote}) =>
    mediaQuery({
      marginLeft: [0, showQuote ? 116 : 0],
      width: ['100%', showQuote ? 630 : '100%'],
    }),
);

export const Quote = styled(Box)(
  mediaQuery({
    padding: ['20px 30px', '25px 30px'],
    borderRadius: 30,
    width: ['calc(100% - 90px)', '100%'],
    maxWidth: ['none', 630],
    background: 'white',
    border: `solid 1px ${lightGray}90`,
    textAlign: 'left',
    marginBottom: 15,
    position: 'relative',
    '&:before': {
      content: '" "',
      position: 'absolute',
      left: -20,
      top: 20,
      width: 20,
      height: 35,
      background: `url(${speechBubble}) center center no-repeat`,
    },

    h2: {
      fontWeight: 'bold',
      marginTop: 0,
      marginBottom: 5,
    },
  }),
);

export const Thumbnail = styled(Image)(
  mediaQuery({
    position: 'absolute',
    left: -90,
    top: 0,
    width: 64,
    height: 64,
  }),
);

export const Title = styled(Text)(
  mediaQuery({
    fontSize: [24, 30],
    fontWeight: 800,
    lineHeight: ['26px', '34px'],
    margin: '0 0 10px 0',
  }),
);

export const Subtitle = styled(Text)(
  mediaQuery({
    fontSize: [16, 20],
    fontWeight: 300,
  }),
  ({color}) => ({
    color: color ? color : 'inherit',
  }),
);

export const Link = styled(RebassLink)(
  mediaQuery({
    fontSize: [16, 18],
    fontWeight: 300,
    color: red,
    marginTop: 12,
    display: 'inline-block',
    paddingRight: 20,
    textDecoration: 'none',
    background: `url(${linkIcon}) no-repeat 100% 6px`,
    ':hover': {
      textDecoration: 'underline',
    },
  }),
);

export const ButtonWrapper = styled(Flex)(
  mediaQuery({
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 12,
    marginRight: [0, 12],
    position: 'relative',
    display: 'inline-block',
  }),
  ({width}) =>
    mediaQuery({
      width: ['100%', width],
    }),
);
