import React, {useState, useEffect} from 'react';

import PropTypes from 'prop-types';
import {Box, Text, Flex, Image} from 'rebass';

import {Alert, Button, TextField, Modal} from '@renofi/components';
import {isEmail} from '@renofi/utils/src/validate';
import {darkGray, gray} from '@renofi/utils/src/colors';

import iconSrc from './login-link.png';
import {modalCustomStyles} from './styled';

const ReturnModal = ({
  show,
  title,
  subtitle,
  email = '',
  id,
  onClose,
  onSubmit,
  success,
}) => {
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('');

  useEffect(() => {
    setShowError(false);
    setLoading(false);
  }, [show]);

  useEffect(() => {
    setValue(email);
  }, [email]);

  function onSubmitHandler() {
    setLoading(true);
    onSubmit(value);
  }

  return (
    <Modal
      id={id}
      onClose={onClose}
      show={show}
      css={modalCustomStyles}
      width={780}>
      <Flex
        flexDirection="column"
        alignItems="center"
        px={[0, 60]}
        width={['100%', 740]}
        pb={30}
        textAlign="center">
        <Image src={iconSrc} width={[196, 160]} />

        <Text color={gray} my={25} fontSize={18} fontWeight={300}>
          {email}
        </Text>

        <Text fontSize={24} color={darkGray} lineHeight="30px">
          {success
            ? 'Check your email.'
            : title || 'This email address was previously used on RenoFi.com'}
        </Text>

        <Box>
          <Text color={gray} my={25} fontSize={18} fontWeight={300}>
            {success
              ? 'We just sent you an email containing a login link. Please open the email and use the login link to continue.'
              : subtitle ||
                'Click the button below and we’ll send a login email so you don’t have to enter your details again.'}
          </Text>

          {showError ? (
            <Alert mb={20}>
              We didn’t find a match for {value}. Check your email address and
              try again.
            </Alert>
          ) : null}

          {!email && !success && (
            <TextField
              width={300}
              mx="auto"
              mt={30}
              mb={20}
              placeholder="email@address.com"
              value={value}
              onChange={(value) => setValue(value)}
              id="email"
              name="email"
              type="email"
            />
          )}

          <Button
            mt={10}
            onClick={onSubmitHandler}
            disabled={!isEmail(value) || loading || success}
            loading={!success && loading}
            success={success}>
            {success
              ? 'Login link sent!'
              : success
                ? 'Sending'
                : 'Resend login link'}
          </Button>
        </Box>
      </Flex>
    </Modal>
  );
};

ReturnModal.propTypes = {
  show: PropTypes.bool,
  success: PropTypes.bool,
  email: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  id: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ReturnModal;
