import React, {memo} from 'react';

//import PropTypes from 'prop-types';
import {StyledButton} from './styled';

const Button = (props) => {
  return <StyledButton {...props} />;
};

export default memo(Button);
