import {makeVar} from '@apollo/client';

// Reactive variables
export const loanAmountVar = makeVar(0);

// Setter
export const setLoanAmount = (loanAmount) => {
  loanAmountVar(loanAmount);
};

// Apollo field policy
export const loanAmountPolicy = {
  read() {
    return loanAmountVar();
  },
};
