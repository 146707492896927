import {gql} from '@apollo/client';

export const GET_NEW_HOMEOWNER = gql`
  query {
    newHomeowner @client {
      firstName
      lastName
      email
      city
      phone
      hasNewsletterConsent
      creditScore
      state
      zipCode
      afterRenovatedValue
      homeValue
      firstMortgageBalance
      loanAmountDesiredForRenovation
      loanSubject
      propertyPurchasePrice
      renovationCost
      estimatedDownpayment
      stageOfPurchase
    }
  }
`;
