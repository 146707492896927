import React, {lazy, memo, Suspense} from 'react';

import {Switch, Route, Redirect, useParams} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Flex} from 'rebass';

import {useScreenSize} from '@renofi/utils';
import {CookieConsentModal, FooterLinks} from '@renofi/components';

import {Container} from '../App/styled';
import SimpleHeader from '../Header/SimpleHeader';
import {initLogAnalytics} from '../analytics';
import {isInIframe} from '../utils';

import {GradientPanel} from './styled';
import Zip from './Zip';
import Financials from './Financials';

const Help = lazy(() => import('./Help'));
const ReturnPopup = lazy(() => import('./ReturnPopup'));

const Intro = ({mlo}) => {
  const {page} = useParams();
  const {isDesktop} = useScreenSize();
  const showHeader = isDesktop || page === 'zip' || page === 'financials';

  if (mlo) {
    return <h3>Coming soon ...</h3>;
  }

  return (
    <>
      <Suspense fallback={null}>
        {showHeader && <ReturnPopup />}
        <Container width={542}>
          {showHeader && <SimpleHeader />}
          <GradientPanel>
            <Switch>
              <Route path="/homeowner/zip">
                <Zip />
              </Route>
              <Route path="/homeowner/help">
                <Help />
              </Route>
              <Route path="/homeowner/financials">
                <Financials />
              </Route>

              <Redirect to="/homeowner/zip" />
            </Switch>
          </GradientPanel>

          {isDesktop && (
            <Flex alignItems="center" flexDirection="column" pb={20}>
              <FooterLinks center />
            </Flex>
          )}
        </Container>
      </Suspense>

      <CookieConsentModal
        initLogAnalytics={initLogAnalytics}
        isInIframe={isInIframe()}
        isLead={false}
      />
    </>
  );
};

Intro.propTypes = {
  mlo: PropTypes.bool,
};

Intro.displayName = 'Intro';

export default memo(Intro);
