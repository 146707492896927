import {first, isArray, size} from 'lodash';
import {useQuery} from '@apollo/client';
import {useParams} from 'react-router-dom';

import {RENOVATION_SCENARIO} from '@renofi/api';
import {
  helocMock,
  homeEquityMock,
  usAllianceMock,
} from '@renofi/api/src/queries/renovationScenario.mock';
import {unique} from '@renofi/utils/src/array';
import {usePrimaryAndSecondaryProducts} from '@renofi/utils';

import {setNewHomeowner} from '../cache';

export default function useScenario(
  customData,
  maxProducts = 3,
  skipInitialProduct,
) {
  const urlParams = new URLSearchParams(window.location.search);
  const medalParam = urlParams.get('medal');

  let data, loading, error;
  let {token, mock, productId} = useParams();

  if (process.env.NODE_ENV === 'test') {
    token = 'mockedToken';
  }

  const rsp = useQuery(RENOVATION_SCENARIO, {
    variables: {token},
    skip: !token || token === 'preview',
  });

  if (mock) {
    data = mock === 'equity' ? homeEquityMock.data : helocMock.data;
    if (Number(mock) > 1400) {
      data = usAllianceMock.data;
    }
    loading = false;
    error = false;
  } else if (customData) {
    data = customData;
    loading = false;
    error = false;
  } else {
    data = rsp.data;
    loading = rsp.loading;
    error = rsp.error;
  }
  const {
    positionedInterestRate,
    preferredProductMatch,
    loanProductMatches,
    lead,
    recommendedLender,
    preferredLender,
  } = data?.result || {};

  const isPreferredProduct = Boolean(size(preferredProductMatch));
  const {primaryOptions, secondaryOptions} = usePrimaryAndSecondaryProducts(
    data?.result?.calculatorLoanProducts || [],
    maxProducts,
  );
  const calculatorLoanProducts = isPreferredProduct
    ? [preferredProductMatch.product]
    : [...primaryOptions, ...secondaryOptions];

  const calculatorProductsWithDefaults = calculatorLoanProducts.map(
    (product) => {
      if (product.productType !== 'purchase_loan') return product;
      return {
        ...product,
        maxPurchaseLtv: product.maxPurchaseLtv || 0.95,
      };
    },
  );

  if (!data || !data.result) return {loading, error, scenario: {}};

  if (isArray(calculatorLoanProducts) && calculatorLoanProducts.length === 0) {
    error = 'No products found.';
  }

  const initialProduct = skipInitialProduct
    ? customData?.result?.initialProduct || {}
    : first(calculatorLoanProducts);
  const productByParamId = calculatorLoanProducts.find(
    (item) => String(item.id) === String(productId || mock),
  );
  const product = productByParamId || initialProduct;
  const {repaymentPeriod, rates, variants} = product || {};
  const uniqueVariants = removeDuplicatedVariants(variants);

  if (lead) {
    setNewHomeowner({...lead, ...(medalParam ? {medal: medalParam} : {})});
  }

  return {
    scenario: {
      initialRates: rates,
      initialPeriod: repaymentPeriod,
      positionedInterestRate,
      isPreferredProduct,
      productMatches: isPreferredProduct
        ? preferredProductMatch
        : loanProductMatches,
      loanProduct: {
        ...product,
        exampleRate: product?.rates && product.rates[0],
        variants: uniqueVariants,
      },
      products: calculatorProductsWithDefaults,
      lead: {
        ...lead,
        loanAmountDesiredForRenovation:
          lead.loanAmountDesiredForRenovation || lead.renovationCost || 0,
        ...(medalParam ? {medal: medalParam} : {}),
      },
      lender: preferredLender || recommendedLender || {},
    },
    loading,
    error,
  };
}

const removeDuplicatedVariants = (variants) => {
  if (!variants) return variants;

  return variants
    .map((variant) => {
      return {
        ...variant,
        rates: variant.rates.filter(unique),
      };
    })
    .filter(
      (variant, index, self) =>
        index ===
        self.findIndex(
          (item) => item.repaymentPeriod === variant.repaymentPeriod,
        ),
    );
};
