import styled from '@emotion/styled/macro';
import {Box, Text, Image, Link as RebassLink} from 'rebass';

import {Panel} from '@renofi/components';
import {mediaQuery} from '@renofi/utils';
import {darkBlue, fadeGray} from '@renofi/utils/src/colors';

export const Container = styled(Box)(
  mediaQuery({
    boxSizing: ['border-box', 'content-box'],
    padding: ['20px 0 0 0', 20],
    margin: '0 auto',
    width: [`100%`, 920],
  }),
);

export const Logo = styled(Image)(
  mediaQuery({
    display: 'block',
    width: 160,
    paddingBottom: 30,
    height: 'auto',
    margin: '0 auto',
  }),
);

export const Title = styled(Box)(
  mediaQuery({
    color: darkBlue,
    fontSize: 24,
    marginBottom: 20,
    lineHeight: '30px',
  }),
);

export const Link = styled(RebassLink)({
  textDecoration: 'underline',
});

export const Content = styled(Panel)(
  mediaQuery({
    padding: [0, '40px 80px'],
    marginTop: 0,
  }),
);

export const BorderBox = styled(Box)(
  mediaQuery({
    border: 'solid 1px #D8D8D8',
    padding: 15,
    color: '#777777',
    borderRadius: 4,
    width: ['100%', '47%'],
    marginBottom: [20, 0],
  }),
);

export const BorderBoxTitle = styled(Text)({
  fontWeight: 'bold',
  textAlign: 'center',
  borderBottom: 'solid 1px #D8D8D8',
  margin: '0 -15px',
  paddingBottom: 18,
  paddingTop: 3,
  marginBottom: 15,
});

export const BorderBoxStrong = styled(Text)({
  position: 'relative',
  fontWeight: 'bold',
  textAlign: 'center',
  padding: '10px 0',
  marginBottom: 15,
  ':before': {
    fontWeight: 'normal',
    content: '"or"',
    width: 50,
    height: 20,
    background: fadeGray,
    position: 'absolute',
    top: -24,
    left: 'calc(50% - 25px)',
  },
});

export const MessageBox = styled(Box)({
  background: '#E5F5FF',
  padding: '10px 15px',
  lineHeight: '20px',
  color: '#006099',
  marginBottom: 20,
  borderRadius: 4,
});
