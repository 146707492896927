import React from 'react';

import PropTypes from 'prop-types';

import {Wrapper, Text} from './styled';

const Badge = ({
  children,
  color,
  size,
  fontWeight = 'bold',
  textColor,
  large,
  xLarge,
  textTransform = 'uppercase',
  ...props
}) => {
  return (
    <Wrapper color={color} large={large} xLarge={xLarge} {...props}>
      <Text
        xLarge={xLarge}
        fontSize={size || 10}
        fontWeight={fontWeight}
        textColor={textColor}
        textTransform={textTransform}>
        {children}
      </Text>
    </Wrapper>
  );
};

Badge.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  large: PropTypes.bool,
  xLarge: PropTypes.bool,
  size: PropTypes.number,
  textColor: PropTypes.string,
};

export default Badge;
