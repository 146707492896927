import React from 'react';

import PropTypes from 'prop-types';

import {PanelDark} from '@renofi/components';

import {Text} from '../styled';

const LoanTerm = ({terms = []}) => {
  const firstTerms = terms.slice(0, -1).join(', ');
  const lastTerm = terms.slice(-1);

  return (
    <PanelDark title="Loan term">
      {terms.length ? (
        <Text mb={10}>
          The RenoFi Home Equity Loan being offered by some of our lending
          partners has term options of {firstTerms} and {lastTerm} years.
        </Text>
      ) : null}

      <Text mb={10}>
        Your term selection will impact your estimated minimum monthly payment.
      </Text>

      <Text mb={10}>Contact your Loan Advisor for more details.</Text>
    </PanelDark>
  );
};

LoanTerm.propTypes = {
  terms: PropTypes.array.isRequired,
};

export default LoanTerm;
