import React from 'react';

import PropTypes from 'prop-types';

import {sendEvent} from '@renofi/analytics';

import {Link} from '../styled';

export default function Links({analyticsPrefix}) {
  return (
    <>
      <Link
        onClick={() => sendEvent(`${analyticsPrefix}/Apply-Checklist-Link`)}
        target="_blank"
        href="https://www.renofi.com/blog/applying-for-a-renovation-home-equity-loan-checklist/">
        I want to know what I need to have ready to apply
      </Link>
      <br />
      <Link
        onClick={() => sendEvent(`${analyticsPrefix}/Qualified-Link`)}
        target="_blank"
        href="https://www.renofi.com/blog/how-to-qualify-for-renovation-home-equity-loan/">
        I’m not sure if I’m qualified
      </Link>
      <br />
      <Link
        onClick={() => sendEvent(`${analyticsPrefix}/How-It-Works-Link`)}
        target="_blank"
        href="https://www.renofi.com/how-it-works/">
        I don’t understand how the loan works
      </Link>
      <br />
      <Link
        onClick={() => sendEvent(`${analyticsPrefix}/Faq-Link`)}
        target="_blank"
        href="https://www.renofi.com/faq/">
        I have a lot of general questions
      </Link>
    </>
  );
}

Links.propTypes = {
  analyticsPrefix: PropTypes.string,
};
