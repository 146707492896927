import React from 'react';

import PropTypes from 'prop-types';

import {Wrapper} from './styled';

const HelpBox = ({children, css}) => {
  return <Wrapper css={css}>{children}</Wrapper>;
};

HelpBox.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
  css: PropTypes.object,
};

export default HelpBox;
