import React from 'react';

import PropTypes from 'prop-types';
import {Flex, Link} from 'rebass';

import {StepSlider} from '../components';

const TermsSlider = ({terms = [], onChange, disabled, ...props}) => {
  return (
    <StepSlider
      {...props}
      disabled={disabled}
      tooltipProps={{terms: terms}}
      label="Loan term"
      suffix="Years"
      large
      onChange={onChange}
      labels={terms}>
      <Flex mt="5px" css={{cursor: 'pointer'}} justifyContent="space-between">
        {terms.map((value, index) => {
          return (
            <Link
              key={index}
              onClick={() => onChange(value)}
              data-testid={`${value}-years`}>
              {value} Years
            </Link>
          );
        })}
      </Flex>
    </StepSlider>
  );
};

TermsSlider.propTypes = {
  terms: PropTypes.array,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default TermsSlider;
