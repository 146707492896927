import React, {memo, useEffect, useRef, useState} from 'react';

import {
  Switch,
  Route,
  Redirect,
  useParams,
  useLocation,
  useHistory,
} from 'react-router-dom';
import {useScrollPosition} from '@n8tb1t/use-scroll-position';
import {FixedBottom} from 'react-fixed-bottom';

import {useUnleashContext} from '@renofi/utils/src/feature-flags';
import {Panel, Toggle, Analytics} from '@renofi/components';
import {
  getEnvironmentUrl,
  usePrepareUnleashContext,
  useScreenSize,
} from '@renofi/utils';
import {sendEvent} from '@renofi/analytics';

import {useNewHomeownerCache, useScenario} from '../api';
import Banner from '../Banner';
import Footer from '../Footer';
import Header from '../Header';
import Error from '../Error';
import ContactButton from '../ContactButton';
import Preview from '../Preview';
import Calculator from '../Calculator';
import WhatNext from '../WhatNext';
import {useUSAllianceMock} from '../api/hooks';
import {sendIFrameMessage} from '../utils';

import {Container, Content} from './styled';

function Page({allowRedirect}) {
  // Apollo
  const {scenario, loading, error} = useScenario();
  const {isUSAllianceMock} = useUSAllianceMock();
  const {newHomeowner} = useNewHomeownerCache();
  const lead = scenario?.lead || newHomeowner;
  const {products} = scenario || {};
  // App
  const urlParams = new URLSearchParams(window.location.search);
  const {token, step, productId} = useParams();
  const [showContact, setShowContact] = useState(true);
  const scrollToRef = useRef(null);
  const whatNextRef = useRef(null);
  const history = useHistory();
  const {isMobile} = useScreenSize();
  const {pathname} = useLocation();
  const isPreview = pathname.includes('/preview');
  const embedParam = urlParams.get('embed') === 'true';
  const updateContext = useUnleashContext();
  const contextReady = usePrepareUnleashContext(updateContext, lead);
  const app = document.getElementById('app');

  const canRedirect =
    lead?.prequalCompletedAt &&
    lead?.id &&
    products?.length &&
    !embedParam &&
    !step &&
    contextReady &&
    allowRedirect;

  useScrollPosition(
    ({currPos}) => {
      setShowContact(currPos.y - window.innerHeight > 0);
    },
    [],
    whatNextRef,
  );

  useEffect(() => {
    setTimeout(() => sendIFrameMessage({iFrameHeight: app?.offsetHeight}));
  }, [loading, productId, app?.offsetHeight]);

  useEffect(() => {
    setTimeout(() => {
      if (isMobile && step && scrollToRef?.current) {
        window.scrollTo({
          behavior: 'smooth',
          top: scrollToRef.current.getBoundingClientRect().top + window.scrollY,
        });
      }
    }, 600);
  }, []);

  if (canRedirect) {
    sendEvent('Calculator/Redirect-To-Dashboard');
    const dashboardAppUrl = getEnvironmentUrl('dashboard', '4012');
    const productPath = productId ? `/product/${productId}` : '';
    window.location = `${dashboardAppUrl}/${lead.id}/calculator${productPath}`;
    return null;
  }

  if ((!isPreview && !contextReady) || loading) return null;

  if (!isPreview && (error || !scenario)) {
    return <Error />;
  }

  if (pathname.includes('/new-renovation') && !newHomeowner.email) {
    history.push(`/renovation/${token}`);
  }

  return (
    <Analytics lead={lead} loanProducts={products}>
      <div>
        <Container embed={embedParam}>
          <Toggle show={!embedParam}>
            <Header />
          </Toggle>

          <Content embed={embedParam} bg="white">
            <Toggle show={!embedParam}>
              <Banner />
            </Toggle>

            <Switch>
              <Route exact path="/renovation/:token?/product/:productId">
                <Calculator scrollToRef={scrollToRef} scenario={scenario} />
              </Route>
              <Route exact path="/renovation/preview/:step?">
                <Preview scrollToRef={scrollToRef} />
              </Route>
              <Route exact path="/renovation/:token">
                <Calculator scrollToRef={scrollToRef} scenario={scenario} />
              </Route>
              <Route path="/new-renovation/:token/:step?">
                <Calculator scrollToRef={scrollToRef} scenario={scenario} />
              </Route>
              <Route path="/adu/:mock">
                <Calculator scrollToRef={scrollToRef} scenario={scenario} />
              </Route>
              <Route path="/product/:mock">
                <Calculator scrollToRef={scrollToRef} scenario={scenario} />
              </Route>
              <Redirect to="/homeowner/zip" />
            </Switch>
          </Content>

          <Toggle show={!isPreview && !embedParam && !isUSAllianceMock}>
            <div ref={whatNextRef} id="what-next">
              <Panel title="What’s next?">
                <WhatNext subtitle="I'm Lee from the RenoFi Advisor team, we are here to help." />
              </Panel>
            </div>
          </Toggle>

          <Toggle show={!embedParam || isMobile}>
            <Footer />
          </Toggle>
        </Container>
        <Toggle show={!embedParam}>
          <FixedBottom>
            <ContactButton show={showContact} whatNextRef={whatNextRef} />
          </FixedBottom>
        </Toggle>
      </div>
    </Analytics>
  );
}

export default memo(Page);
