import styled from '@emotion/styled/macro';
import {Flex, Box, Image} from 'rebass';

import {mediaQuery} from '@renofi/utils';

export const Logo = styled(Image)(
  mediaQuery({
    height: 'auto',
    marginRight: 15,
  }),
  ({width}) => ({
    width: width ? width : [130, 180],
  }),
);

export const Wrapper = styled(Flex)(
  mediaQuery({
    background: ['#F7FCFE', 'none'],
    borderBottom: ['solid 1px #EAEAEA', 'none'],
    display: 'flex',
    color: ['gray', 'inherit'],
    textAlign: 'right',
    marginBottom: [0, 20],
    marginTop: [0, 10],
    padding: [15, '0 40px'],
  }),
);

export const Borrower = styled(Box)(
  mediaQuery({
    display: 'flex',
    textAlign: 'right',
    alignItems: 'center',
  }),
);
