import {useMemo} from 'react';

import {roundToNextMillion} from '../format';

export default function useMaxValues({
  homeValue = 1,
  firstMortgageBalance = 1,
} = {}) {
  return useMemo(() => {
    const million = 1000000;
    return {
      afterRenovatedValue: Math.max(
        million * 3,
        roundToNextMillion(homeValue * 2),
      ),
      homeValue: Math.max(million * 3, roundToNextMillion(homeValue * 2)),
      propertyPurchasePrice: Math.max(
        million * 3,
        roundToNextMillion(homeValue * 2),
      ),
      firstMortgageBalance: Math.max(
        million * 3,
        homeValue,
        roundToNextMillion(firstMortgageBalance),
      ),
      estimatedDownpayment: Math.max(
        million * 3,
        homeValue,
        roundToNextMillion(firstMortgageBalance),
      ),
    };
  }, [homeValue, firstMortgageBalance]);
}
