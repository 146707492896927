import {InMemoryCache} from '@apollo/client';

import {newHomeownerPolicy as newHomeowner} from './newHomeowner';
import {calculatedValuesPolicy as calculatedValues} from './maxLoan';
import {loanAmountPolicy as loanAmount} from './loanAmount';
import {mortgageBalancePolicy as mortgageBalance} from './mortgageBalance';

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        newHomeowner,
        calculatedValues,
        loanAmount,
        mortgageBalance,
      },
    },
  },
});
