import {Text, Flex} from 'rebass';
import styled from '@emotion/styled/macro';

import {mediaQuery} from '@renofi/utils';
import {darkBlue} from '@renofi/utils/src/colors';

export const FeedbackTitle = styled(Text)(() =>
  mediaQuery({
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '28px',
    lineHeight: '36px',
    textAlign: 'center',
    color: darkBlue,
    marginBottom: '20px',
  }),
);

export const FeedbackSubtitle = styled(Text)(() =>
  mediaQuery({
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '20px',
    lineHeight: '28px',
    textAlign: 'center',
    color: darkBlue,
  }),
);

export const ButtonWrapper = styled(Flex)(() => ({
  width: '100%',
  justifyContent: 'center',
}));

export const ThankYouContainer = styled(Flex)(() =>
  mediaQuery({
    flexDirection: 'column',
    marginTop: ['50%', 0],
  }),
);
